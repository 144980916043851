import { 
    CONTRIBUTORS_REQUEST,
    CONTRIBUTORS_SUCCESS,
    CONTRIBUTORS_FAIL,
    PROFILE_BY_ID_REQUEST,
    PROFILE_BY_ID_SUCCESS,
    PROFILE_BY_ID_FAIL,
    FEATURED_CONTRIBUTOR,
} from "constants/contributorConstants";

const initialState = {
    contributors: [],
    loading: false,
    profile: [],
    featuredContributor: []
}

function getContributorsReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTORS_REQUEST:
            return { ...state, loading: true };
        case CONTRIBUTORS_SUCCESS:
            return { ...state, contributors: action.payload, loading: false};
        case CONTRIBUTORS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getProfileByIdReducer(state = initialState, action) {
    switch (action.type) {
        case PROFILE_BY_ID_REQUEST:
            return { ...state, loading: true };
        case PROFILE_BY_ID_SUCCESS:
            return { ...state, profile: action.payload, loading: false};
        case PROFILE_BY_ID_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getFeaturedContributorReducer( state = initialState, action ) {
    switch (action.type) {
        case FEATURED_CONTRIBUTOR:
            return { ...state, featuredContributor: action.payload };
        default: return state;
    }
}

export {
    getProfileByIdReducer,
    getContributorsReducer,
    getFeaturedContributorReducer
}