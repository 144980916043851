import React from 'react';
import PropTypes from 'prop-types';
import { useThemeContext } from "../../context/themeContext";

const Button = ({
    width,
    height,
    text,
    theme = 'solid',
    textColor,
    onClick,
    fontSize = '1',
    className,
    lineHeight = 'unset',
    borderRadius,
    withIcon = false,
    icon,
}) => {

    const handleClick = (e) => {
       onClick && e.preventDefault();
        onClick && onClick();
    };

    const { themeSetting } = useThemeContext()

    const gradient = `linear-gradient(to left, ${themeSetting.colors.primary}, ${themeSetting.colors.secondary}`;
    
    const style = {
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
        border: 'none',
        padding: `10px`,
        fontSize: `${fontSize}em`,
        lineHeight: `${lineHeight}`,
        backgroundImage: theme === 'gradient' && gradient,
        backgroundColor: theme === 'transparent' ?'transparent' : `${themeSetting.colors.primary}`,
        color: textColor ? textColor : '#fff'
    };

    return (
        <button style={style} className={`${className} font-face-poppins`} onClick={onClick && handleClick}>
            { withIcon && icon }
            {text}
        </button>
    );
};

Button.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['solid', 'gradient', 'transparent']),
  textColor: PropTypes.string,
};

export default Button;