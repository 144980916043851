import { 
    CONTRIBUTORS_BY_KEYWORD_REQUEST,
    CONTRIBUTORS_BY_KEYWORD_SUCCESS,
    CONTRIBUTORS_BY_KEYWORD_FAIL,
    TOP_FORUMS_BY_KEYWORD_REQUEST,
    TOP_FORUMS_BY_KEYWORD_SUCCESS,
    TOP_FORUMS_BY_KEYWORD_FAIL,
} from "constants/contributorConstants";

const initialState = {
    contributors: [],
    forums: [],
    loading: false,
    pollLoading: false
}

function getContributorsByKeywordReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTORS_BY_KEYWORD_REQUEST:
            return { ...state, loading: true };
        case CONTRIBUTORS_BY_KEYWORD_SUCCESS:
            return { ...state, contributors: action.payload, loading: false};
        case CONTRIBUTORS_BY_KEYWORD_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getTopForumsByKeywordReducer(state = initialState, action) {
    switch (action.type) {
        case TOP_FORUMS_BY_KEYWORD_REQUEST:
            return { ...state, loading: true };
        case TOP_FORUMS_BY_KEYWORD_SUCCESS:
            return { ...state, forums: action.payload, loading: false};
        case TOP_FORUMS_BY_KEYWORD_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}



export {
    getContributorsByKeywordReducer,
    getTopForumsByKeywordReducer,
}