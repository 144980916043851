import { 
    GET_FREELANCER_SERVICES,

} from "constants/premiumConstants";

const initialState = {
    loading: false,
    freelancerServices: [],
}

function premiumHomeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FREELANCER_SERVICES:
            return { ...state, freelancerServices: action.payload };
        default: return state;

    }
}

export {
    premiumHomeReducer,

}