import React, { useState, useEffect, useCallback, /*lazy,*/ useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import "@webscopeio/react-textarea-autocomplete/style.css";
import Echo from 'laravel-echo';

import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsBellFill, BsBellSlashFill, BsEmojiSmile } from "react-icons/bs";
import { FaUserPlus } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineOutput, MdAddComment } from "react-icons/md"
import { TbUsers } from "react-icons/tb";

import CustomHeader from 'components/header/CustomHeader'
import Modal from "utils/Modal";
import {
  getMessageContent,
  removeMessage,
  getMoreMessageContent,
  leaveChannel,
  getChannelsById,
  setChannelId,
  goToDmChannel
} from "actions/common/messageActions";
import MessageSettingModal from "components/modals/MessageSettingModal";
import AddChannelModal from "components/modals/AddChannelModal";
import MessageReply from "components/message/MessageReply";
import MessageEditor from "components/message/MessageEditor";
import { useNavigate } from "react-router-dom";
import { addFriend } from 'actions/common/messageActions'

const profileId = Cookies.getJSON('luncsphere_profile') || null;

const PremiumMessageRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState('');
  const [message, setMessage] = useState('');
  const [settingChannel, setSettingChannel] = useState(false);
  const [dmChannels, setDmChannels] = useState([]);
  const [groupChannels, setGroupChannels] = useState([]);
  const [members, setMembers] = useState([]);
  const [messageHeight, setMessageHeight] = useState(localStorage.getItem("messageHeight") || 'calc(100vh - 0px)');
  const [messageContentHeight, setMessageContentHeight] = useState(localStorage.getItem("messageContentHeight") || '');
  const [messageContentPadding, setMessageContentPadding] = useState('');
  const [replyMessage, setReplyMessage] = useState(null);
  const [scrollPos, setScrollPos] = useState(0);

  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [broadcast, setBroadcast] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [responsiveFlag, setResponsiveFlag] = useState(false);

  const messageContainerRef = useRef(null);
  const profile_id = Cookies.getJSON('luncsphere_profile') || null;
  const headerComponent = document.getElementsByClassName('contributor-message-room-header');

  const { channels, after_channel_id, newMessageFlag, receive_channel_id, channel_id, selectedChannel, messageContent, previousUrl, loadMore,loading } = useSelector(state => state.message);

  useEffect(() => {
    localStorage.setItem("messageHeight",messageHeight.toString());
    localStorage.setItem("messageContentHeight",messageContentHeight);
    localStorage.setItem("responsiveFlag",responsiveFlag.toString());
  }, [messageHeight,messageContentHeight,responsiveFlag]);

  useEffect(() => {
    const data = {
      profile_id: profile_id,
      type: "premium"
    }
    dispatch(getChannelsById(data));
    setMessageHeight('calc(100vh - ' + (document.getElementById('header_main').clientHeight) + 'px)');
    setTimeout(() => {
      setMessageContentHeight('calc(100% - ' + (headerComponent?.[0]?.clientHeight
        + document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight) + 'px)');
      setMessageContentPadding(document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight);
    }, 100)

    const handleResize = () => {
      setMessageHeight('calc(100vh - ' + (document.getElementById('header_main').clientHeight) + 'px)');
      setTimeout(() => {
        setMessageContentHeight('calc(100% - ' + (headerComponent?.[0]?.clientHeight
          + document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight) + 'px)');
        setMessageContentPadding(document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight);
      }, 100)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      Cookies.set('channel_id', 0,{ sameSite: 'none' });
    };
  }, [])

  useEffect(() => {
    setMessageContentHeight('calc(100% - ' + (headerComponent?.[0]?.clientHeight
      + document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight) + 'px)');
    setMessageContentPadding(document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight);
  }, [replyMessage])

  useEffect(() => {
    if (channel_id > 0) {
      const data = {
        channel_id: channel_id,
        type: "premium",
        profile_id: profile_id
      }
      dispatch(getMessageContent(data));
    }
    channels && channels.length > 0 && channels.filter(p => p.id === channel_id).length > 0 &&
      setMembers(selectedChannel?.profiles.map(p => ({ name: p.alias ? p.alias : p.name, avatar: p.avatar, profile_id: p.id })))
    setMessageContentHeight('calc(100% - ' + (headerComponent?.[0]?.clientHeight
      + document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight) + 'px)');
    setMessageContentPadding(document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight);
    setSettingChannel(false);
    setMessage("");
    setScrollPos(0);
  }, [channel_id]);

  useEffect(() => {
    if (after_channel_id !== undefined) {
      const data = {
        profile_id: profile_id,
        type: "premium"
      }
      dispatch(getChannelsById(data));
    }
  }, [after_channel_id])

  useEffect(() => {
    if (receive_channel_id !== undefined) {
      const data = {
        profile_id: profile_id,
        type: "premium"
      }
      dispatch(getChannelsById(data));
    }
  }, [receive_channel_id])

  useEffect(() => {
    messageContent && messageContent.length > 0 && scrollToBottom();
  }, [messageContent])

  useEffect(() => {
    if (channels && channels.length > 0 && Array.isArray(channels)) {
      setDmChannels(Array.from(channels).filter(p => p.member_cnt === 2 && p.isFriend === false && p.is_allowed === 0).sort((a, b) => new Date(b.time || b.updated_at) - new Date(a.time || a.updated_at)));
      setGroupChannels(channels.filter(p => p.member_cnt !== 2));
      if (channels && channels.length > 0 && channel_id !== 0 && channels.filter(p => p.id === channel_id).length > 0) {
        setMembers(selectedChannel?.profiles?.map(p => ({ name: p.alias ? p.alias : p.name, avatar: p.avatar, profile_id: p.id })));
      }
    }
  }, [channels])

  const handleScroll = () => {
    const messageContainer = messageContainerRef.current;
    if (previousUrl === "null" || messageContainer.scrollTop !== 0) {
      return;
    }
    const data = {
      channel_id: channel_id,
      previousUrl: previousUrl,
      type: "premium"
    }
    dispatch(getMoreMessageContent(data));
  }

  // Handle Events
  const handleModal = () => {
    setModal(!modal);
  }

  const handleDragover = () => { }

  const handleDrop = () => { }

  const handleCreateChannel = useCallback(() => {
    setIsCreate(true)
    setAddModal(!addModal)
  })

  const handleBroadcast = useCallback(() => {
    setIsCreate(true)
    setBroadcast(!broadcast)
  })

  const handleAddMember = useCallback(() => {
    setIsCreate(false)
    setAddModal(!addModal)
  }, [])

  const handleLeave = () => {
    const data = {
      channel_id: channel_id,
      profile_id: profile_id,
      time: new Date().toUTCString(),
      type: "premium"
    };
    dispatch(leaveChannel(data));
  }

  const handleFriendButton = (friendId) => {
    const data = {
      profile_id: profile_id,
      friend_id: friendId,
      type: 'premium'
    }

    dispatch(addFriend(data));
  }
  const hanldeSelectChannel = (id) => {
    if(loading) return;
    const data = {
      id: id,
      type: "premium"
    }
    dispatch(setChannelId(data));
    setResponsiveFlag(true);
    setTimeout(() => {
      setMessageContentHeight('calc(100% - ' + (headerComponent?.[0]?.clientHeight
        + document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight) + 'px)');
      setMessageContentPadding(document.getElementsByClassName('contributor-message-room-textarea')?.[0]?.clientHeight);
    }, 100)
  }

  const scrollToBottom = useCallback(() => {
    const messageContainer = messageContainerRef.current;
    if (messageContent && messageContainer && messageContent.length > 0) {
      if (messageContainer.scrollHeight - scrollPos < 20 || newMessageFlag) messageContainer.scrollTop = messageContainer.scrollHeight;
      else messageContainer.scrollTop = messageContainer.scrollHeight - scrollPos;
      setScrollPos(messageContainer.scrollHeight - 10);
    }
  }, [messageContent]);

  const handleReply = (message) => {
    setReplyMessage(message);
  }

  const handleDelete = (message) => {
    const data = {
      id: message.id,
      channel_id: channel_id,
      type: "premium"
    }
    dispatch(removeMessage(data));
  }

  const getTimeFormat = (time) => {
    return new Date(new Date(time).toLocaleString()).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
  }
  const handleRequetButton = () => {
    navigate('/premium/message-request');
  }

  const handleBackToMessage = () => {
      navigate('/premium/message');
  }

  return (
    <div className="common-background-gradient min-h-screen relative md:pt-32 contributor-message" onDrop={handleDrop} onDragOver={handleDragover} >
      <CustomHeader active={'Lunc Meets'} />
      <div className="font-face-poppins text-white" style={{ height: messageHeight }}>
        <div className="themesflat-container h-full">
          <div className="grid grid-cols-1 md:grid-cols-2 h-full overflow-auto contributor-message-page-bg">
            <div className={`h-full overflow-auto ${responsiveFlag && 'contributor-message-page-header-hidden'}`} style={{ height: messageHeight, borderRight: '1px solid white' }}>
              <div className="contributor-message-page-users">
                <div className="flex justify-start items-center px-4 contributor-message-users-header">

                  <IoIosArrowBack className="mt-3 cursor-pointer" onClick={handleBackToMessage} />

                  <div className="flex-grow text-center">Message Request</div>
                </div>
                <div className="contributor-message-users-container">
                  {/* <div className="w-full relative search-bar px-4 flex align-middle">
                    <FiSearch className="absolute" />
                    <input
                      type="text"
                      placeholder="Search Message"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      style={{ fontStyle: keyword !== '' ? 'normal' : 'italic' }}
                    />
                  </div>
                  <div className="bg-blue-500 flex justify-end pr-5">
                    <button onClick={() => handleRequetButton()} className="border-none">Request</button>
                  </div> */}
                  {
                    channels !== undefined && channels.length > 0 &&
                    <>
                      <div className="text-3xl ml-3 pt-4">
                        {dmChannels && dmChannels.length > 0 && dmChannels.filter(item => (item?.profiles?.filter(k => k.id !== profile_id)[0]?.alias ? item?.profiles?.filter(k => k.id !== profile_id)[0]?.alias : item?.profiles?.filter(k => k.id !== profile_id)[0]?.name)?.toLowerCase().includes(keyword.toLowerCase())).map((p, index) =>
                          <div
                            className={`contributor-message-room-container cursor-pointer ${p.id === channel_id && "active"} ${loading && "cursor-progress"}`}
                            key={index}
                            onClick={() => hanldeSelectChannel(p.id)}
                          >
                            <img
                              className="inline-block object-cover"
                              src={
                                process.env.REACT_APP_S3_BASE_URL
                                + (p?.profiles?.filter(k => k.id !== profile_id)[0].avatar)
                              }
                              alt={p?.profiles?.filter(k => k.id !== profile_id)[0].title}
                            />
                            <div className="inline-block contributor-message-users-title">
                              <div className="contributor-message-room-name">
                                {p?.profiles?.filter(k => k.id !== profile_id)[0].alias ? p?.profiles?.filter(k => k.id !== profile_id)[0].alias : p?.profiles?.filter(k => k.id !== profile_id)[0].name}
                              </div>
                              <div className="contributor-message-room-content">
                              <div dangerouslySetInnerHTML={{__html: p?.message}}></div>
                              </div>
                            </div>
                            {p?.notifications?.length > 0 && p?.notifications?.filter(p => p.profile_id === profile_id)[0]?.count > 0 &&
                              <div>
                                <div className="unread-number w-12 h-12 text-center rounded-full m-3">
                                  {p?.notifications?.filter(p => p.profile_id === profile_id)[0]?.count || ''}
                                </div>
                              </div>
                            }
                          </div>
                        )}
                        {dmChannels.length !== 0 && dmChannels.filter(item => (item?.profiles?.filter(k => k.id !== profile_id)[0]?.alias ? item?.profiles?.filter(k => k.id !== profile_id)[0]?.alias : item?.profiles?.filter(k => k.id !== profile_id)[0]?.name)?.toLowerCase().includes(keyword.toLowerCase())).length === 0 &&
                          <div className=" text-center px-0 py-3">Nothing found</div>
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className={`h-full overflow-auto ${!responsiveFlag && 'contributor-message-page-header-hidden'}`}>
              {channel_id !== 0 && !selectedChannel?.isFriend && !selectedChannel?.is_allowed &&
                <div className="contributor-message-page-messages h-full flex flex-col">
                  <div className="contributor-message-room-header flex items-center absolute top-0 w-full z-7">
                    <IoIosArrowBack className="hidden cursor-pointer text-5xl contributor-message-page-header-block" onClick={() => setResponsiveFlag(false)} />
                    <img
                      className="inline-block object-cover"
                      src={
                        process.env.REACT_APP_S3_BASE_URL +
                        (
                          channels && selectedChannel?.member_cnt === 2 ?
                            selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].avatar
                            :
                            selectedChannel?.avatar
                        )
                      }
                      alt={
                        channels && selectedChannel?.member_cnt === 2 ?
                          (
                            selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias ?
                              selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias
                              :
                              selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].name
                          )
                          :
                          selectedChannel?.title
                      }
                    />
                    <div className="inline-block contributor-message-users-title">
                      <div className="contributor-message-room-name cursor-pointer" onClick={() => navigate(`/premium/${ selectedChannel?.profiles.filter(member => member.id !== profile_id)[0]?.id}/profile`)}>
                        {
                          channels && selectedChannel?.member_cnt === 2 ?
                            (
                              selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias ?
                                selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias
                                :
                                selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].name
                            )
                            :
                            selectedChannel?.title
                        }
                      </div>
                    </div>
                    <AiOutlineInfoCircle className="cursor-pointer mr-20  text-5xl" onClick={() => setSettingChannel((e) => !e)} />
                  </div>
                  <div
                    className="contributor-message-room-content w-full absolute overflow-auto p-4" onScroll={handleScroll}
                    style={{ height: messageContentHeight, bottom: messageContentPadding + 'px' }} ref={messageContainerRef}>
                    {messageContent && messageContent.map((p, index) =>
                      <div key={index} className="pb-1">
                        {p.leave ?
                          <div className="text-center">
                            <div>
                            <div dangerouslySetInnerHTML={{__html: p?.message}}></div>
                            </div>
                            <div className="opacity-50">
                              {getTimeFormat(p.time)}
                            </div>
                          </div>
                          :
                          <>
                            {p.profile_id === profile_id ?
                              <div className="text-right flex message-content-avatar">
                                <div className="inline-block pr-2">
                                  <MessageReply message={p} index={index} members={members} handleReply={handleReply} handleDelete={handleDelete} classProps="message-text text-left text-white border-none whitespace-pre-line" />
                                  {!((index < messageContent.length - 1) && (new Date(messageContent[index + 1].time) - new Date(p.time) < 60000) && (p.profile_id === messageContent[index + 1].profile_id)) &&
                                    <div className="message-time">{getTimeFormat(p.time)}</div>
                                  }
                                </div>
                                {!((index <= messageContent.length - 1) && (index > 0 && p.profile_id === messageContent[index - 1].profile_id)) &&
                                  <img
                                    className="rounded-full inline-block"
                                    src={process.env.REACT_APP_S3_BASE_URL + selectedChannel?.profiles?.filter(member => member.id === p.profile_id)[0].avatar}
                                    alt={process.env.REACT_APP_S3_BASE_URL + selectedChannel?.profiles?.filter(member => member.id === p.profile_id)[0].alias}
                                  />
                                }
                              </div>
                              :
                              <div className="message-content-avatar flex flex-row-reverse">
                                <div className="pl-2">
                                  <MessageReply message={p} index={index} handleReply={handleReply} handleDelete={handleDelete} classProps="message-text-received text-white border-none whitespace-pre-line" />
                                  {!((index < messageContent.length - 1) && (new Date(messageContent[index + 1].time) - new Date(p.time) < 60000) && (p.profile_id === messageContent[index + 1].profile_id)) &&
                                    <div className="message-time">{getTimeFormat(p.time)}</div>
                                  }
                                </div>
                                {!((index <= messageContent.length - 1) && (index > 0 && p.profile_id === messageContent[index - 1].profile_id)) &&
                                  <>
                                    <img
                                      className="rounded-full inline-block"
                                      src={process.env.REACT_APP_S3_BASE_URL + selectedChannel?.profiles?.filter(member => member.id === p.profile_id)[0]?.avatar}
                                      alt={process.env.REACT_APP_S3_BASE_URL + selectedChannel?.profiles?.filter(member => member.id === p.profile_id)[0]?.alias}
                                    />
                                  </>
                                }
                              </div>
                            }
                          </>
                        }
                      </div>
                    )}
                  </div>
                  <MessageEditor
                    setMessage={setMessage}
                    message={message}
                    channel_id={channel_id}
                    profile_id={profile_id}
                    replyMessage={replyMessage}
                    setReplyMessage={setReplyMessage}
                    messageContentHeight={messageContentHeight}
                    messageContentPadding={messageContentPadding}
                    getTimeFormat={getTimeFormat}
                    members={members}
                    type="premium"
                  />
                  {
                    settingChannel &&
                    <div className="contributor-message-group-setting z-10">
                      <IoIosArrowBack className="ml-3 mt-3 group-setting-close cursor-pointer" onClick={() => setSettingChannel(false)} />
                      <div className="flex flex-col items-center mt-10">
                        <img
                          className="inline-block group-setting-image object-cover"
                          src={
                            process.env.REACT_APP_S3_BASE_URL +
                            (
                              selectedChannel?.member_cnt === 2 ?
                                selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].avatar
                                :
                                selectedChannel?.avatar
                            )
                          }
                          alt={
                            selectedChannel?.member_cnt === 2 ?
                              (
                                selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias ?
                                  selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias
                                  :
                                  selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].name
                              )
                              :
                              selectedChannel?.title
                          }
                        />
                        <div className="contributor-message-room-name mt-4 text-center">
                          {
                            selectedChannel?.member_cnt === 2 ?
                              (
                                `Direct Chat with ${selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias ?
                                  selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].alias
                                  :
                                  selectedChannel?.profiles.filter(member => member.id !== profile_id)[0].name
                                }`
                              )
                              :
                              selectedChannel?.title
                          }
                        </div>
                        {selectedChannel?.member_cnt !== 2 &&
                          <div className="group-setting-option-btn" onClick={handleModal}>Change group name & photo</div>
                        }
                        <div className="flex mt-16 group-setting-option">
                          <div className="flex flex-col items-center cursor-pointer" onClick={() => handleFriendButton(selectedChannel?.member_cnt === 2 && selectedChannel?.profiles?.filter(member => member.user_id != profile_id)[0].user_id)}><FaUserPlus className="group-setting-option-icon" /><span>Add Friend</span></div>
                          <div className="flex flex-col items-center cursor-pointer"><BsBellFill className="group-setting-option-icon mx-28" /><span>Mute</span></div>
                          <div className="flex flex-col items-center cursor-pointer" onClick={handleLeave}><MdOutlineOutput className="group-setting-option-icon" /><span>Leave</span></div>
                        </div>
                      </div>

                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {
        settingChannel &&
        <Modal open={modal} onClose={handleModal} modalSize={4}>
          <MessageSettingModal
            id={selectedChannel?.id}
            title={selectedChannel?.title}
            avatar={selectedChannel?.avatar}
            handleModalDown={handleModal}
          />
        </Modal>
      }

      <Modal open={addModal} onClose={() => setAddModal(!addModal)} modalSize={4}>
        <AddChannelModal
          handleModalDown={() => setAddModal(!addModal)}
          placeholder="Premium"
          channels={channels}
          isCreate={isCreate}
          channel_id={channel_id}
          profile_id={profile_id}
          isBroadCasting={false}
          type='premium'
        />
      </Modal>

    </div>
  )
}

export default PremiumMessageRequest