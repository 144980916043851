export const CONTRIBUTOR_LOGIN_REQUEST = 'CONTRIBUTOR_LOGIN_REQUEST';
export const CONTRIBUTOR_LOGIN_SUCCESS = 'CONTRIBUTOR_LOGIN_SUCCESS';
export const CONTRIBUTOR_LOGIN_FAIL = 'CONTRIBUTOR_LOGIN_FAIL';
export const CONTRIBUTOR_LOGOUT = 'CONTRIBUTOR_LOGOUT';
export const CONTRIBUTOR_REGISTER_REQUEST = 'CONTRIBUTOR_REGISTER_REQUEST';
export const CONTRIBUTOR_REGISTER_SUCCESS = 'CONTRIBUTOR_REGISTER_SUCCESS';
export const CONTRIBUTOR_REGISTER_FAIL = 'CONTRIBUTOR_REGISTER_FAIL';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const SEND_RESET_PASSWORD_REQUEST = 'SEND_RESET_PASSWORD_REQUEST';
export const SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_FAIL = 'SEND_RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAIL = 'CREATE_PROFILE_FAIL';
export const ADD_PROFILE = 'ADD_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const UPDATE_REMOVE_PROFILE = 'UPDATE_REMOVE_PROFILE';
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const GET_WHITEPAPERS_REQUEST = 'GET_WHITEPAPERS_REQUEST';
export const GET_WHITEPAPERS_SUCCESS = 'GET_WHITEPAPERS_SUCCESS';
export const GET_WHITEPAPERS_FAIL = 'GET_WHITEPAPERS_FAIL';
export const CREATE_WHITEPAPER_REQUEST = 'CREATE_WHITEPAPER_REQUEST';
export const CREATE_WHITEPAPER_SUCCESS = 'CREATE_WHITEPAPER_SUCCESS';
export const CREATE_WHITEPAPER_FAIL = 'CREATE_WHITEPAPER_FAIL';
export const CONTRIBUTOR_ROLES_REQUEST = 'CONTRIBUTOR_ROLES_REQUEST';
export const CONTRIBUTOR_ROLES_SUCCESS = 'CONTRIBUTOR_ROLES_SUCCESS';
export const CONTRIBUTOR_ROLES_FAIL = 'CONTRIBUTOR_ROLES_FAIL';
export const CONTRIBUTORS_BY_KEYWORD_REQUEST = 'CONTRIBUTORS_BY_KEYWORD_REQUEST';
export const CONTRIBUTORS_BY_KEYWORD_SUCCESS = 'CONTRIBUTORS_BY_KEYWORD_SUCCESS';
export const CONTRIBUTORS_BY_KEYWORD_FAIL = 'CONTRIBUTORS_BY_KEYWORD_FAIL';
export const TOP_FORUMS_BY_KEYWORD_REQUEST = 'TOP_FORUMS_BY_KEYWORD_REQUEST';
export const TOP_FORUMS_BY_KEYWORD_SUCCESS = 'TOP_FORUMS_BY_KEYWORD_SUCCESS';
export const TOP_FORUMS_BY_KEYWORD_FAIL = 'TOP_FORUMS_BY_KEYWORD_FAIL';
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';
export const CREATE_NEWS_REQUEST = 'CREATE_NEWS_REQUEST';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';
export const CONTRIBUTORS_REQUEST = 'CONTRIBUTORS_REQUEST';
export const CONTRIBUTORS_SUCCESS = 'CONTRIBUTORS_SUCCESS';
export const CONTRIBUTORS_FAIL = 'CONTRIBUTORS_FAIL';
export const PROFILE_BY_ID_REQUEST = 'PROFILE_BY_ID_REQUEST';
export const PROFILE_BY_ID_SUCCESS = 'PROFILE_BY_ID_SUCCESS';
export const PROFILE_BY_ID_FAIL = 'PROFILE_BY_ID_FAIL';
export const GET_EVENTS_CATEGORIES = 'GET_EVENTS_CATEGORIES';
export const GET_EVENTS_BY_MONTH_REQUEST = 'GET_EVENTS_BY_MONTH_REQUEST';
export const GET_EVENTS_BY_MONTH_SUCCESS = 'GET_EVENTS_BY_MONTH_SUCCESS';
export const GET_EVENTS_BY_MONTH_FAIL = 'GET_EVENTS_BY_MONTH_FAIL';
export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES';
export const GET_NEWS_CATEGORIES_REQUEST = 'GET_NEWS_CATEGORIES_REQUEST';
export const NEWS_CATEGORY_ID = 'NEWS_CATEGORY_ID';
export const NEWS_REQUEST = 'NEWS_REQUEST';
export const NEWS_SUCCESS = 'NEWS_SUCCESS';
export const NEWS_FAIL = 'NEWS_FAIL';
export const NEWS_BY_ID_REQUEST = 'NEWS_BY_ID_REQUEST';
export const NEWS_BY_ID_SUCCESS = 'NEWS_BY_ID_SUCCESS';
export const NEWS_BY_ID_FAIL = 'NEWS_BY_ID_FAIL';
export const EVENT_BY_ID_REQUEST = 'EVENT_BY_ID_REQUEST';
export const EVENT_BY_ID_SUCCESS = 'EVENT_BY_ID_SUCCESS';
export const EVENT_BY_ID_FAIL = 'EVENT_BY_ID_FAIL';
export const FEATURED_CONTRIBUTOR = 'FEATURED_CONTRIBUTOR';
export const CREATE_FORUM_REQUEST = 'CREATE_FORUM_REQUEST';
export const CREATE_FORUM_SUCCESS = 'CREATE_FORUM_SUCCESS';
export const CREATE_FORUM_FAIL = 'CREATE_FORUM_FAIL';
export const FORUM_BY_ID_REQUEST = 'FORUM_BY_ID_REQUEST';
export const FORUM_BY_ID_SUCCESS = 'FORUM_BY_ID_SUCCESS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_COMMENTS_BY_FORUMID = 'GET_COMMENTS_BY_FORUMID';
export const FORUM_BY_ID_FAIL = 'FORUM_BY_ID_FAIL';
export const HANDLE_POLL_OPTION_REQUEST = 'HANDLE_POLL_OPTION_REQUEST';
export const HANDLE_POLL_OPTION_SUCCESS = 'HANDLE_POLL_OPTION_SUCCESS';
export const HANDLE_POLL_OPTION_FAIL = 'HANDLE_POLL_OPTION_FAIL';
export const FORUMS_BY_KEYWORD_REQUEST = 'FORUMS_BY_KEYWORD_REQUEST';
export const FORUMS_BY_KEYWORD_SUCCESS = 'FORUMS_BY_KEYWORD_SUCCESS';
export const FORUMS_BY_KEYWORD_FAIL = 'FORUMS_BY_KEYWORD_FAIL';
export const FORUM_HANDLE_LIKES = 'FORUM_HANDLE_LIKES';
export const FORUM_HANDLE_VIEWS = 'FORUM_HANDLE_VIEWS';
export const FORUM_COMMENT_HANDLE_LIKES = 'FORUM_COMMENT_HANDLE_LIKES';
export const NEW_COMMENT = "NEW_COMMENT";
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const EVENT_NEAREST_REQUEST = 'EVENT_NEAREST_REQUEST';
export const EVENT_NEAREST_SUCCESS = 'EVENT_NEAREST_SUCCESS';
export const EVENT_NEAREST_FAIL = 'EVENT_NEAREST_FAIL';
export const CREATE_BLOGS_REQUEST = 'CREATE_BLOGS_REQUEST';
export const CREATE_BLOGS_SUCCESS = 'CREATE_BLOGS_SUCCESS';
export const CREATE_BLOGS_FAIL = 'CREATE_BLOGS_FAIL';
export const BLOGS_REQUEST = 'BLOGS_REQUEST';
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS';
export const BLOGS_FAIL = 'BLOGS_FAIL';
export const BLOGS_BY_ID_REQUEST = 'BLOGS_BY_ID_REQUEST';
export const BLOGS_BY_ID_SUCCESS = 'BLOGS_BY_ID_SUCCESS';
export const BLOGS_BY_ID_FAIL = 'BLOGS_BY_ID_FAIL';
export const BLOG_COMMENTS_REQUEST = 'BLOG_COMMENTS_REQUEST';
export const BLOG_COMMENTS_SUCCESS = 'BLOG_COMMENTS_SUCCESS';
export const BLOG_COMMENTS_FAIL = 'BLOG_COMMENTS_FAIL';
export const BLOG_LIKES_REQUEST = 'BLOG_LIKES_REQUEST';
export const BLOG_LIKES_SUCCESS = 'BLOG_LIKES_SUCCESS';
export const BLOG_LIKES_FAIL = 'BLOG_LIKES_FAIL';
export const BLOG_COMMENT_REQUEST = 'BLOG_COMMENT_REQUEST';
export const BLOG_COMMENT_SUCCESS = 'BLOG_COMMENT_SUCCESS';
export const BLOG_COMMENT_FAIL = 'BLOG_COMMENT_FAIL';
export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_CHANNELS_BY_ID = 'GET_CHANNELS_BY_ID';
export const GET_CHANNELS_FAILED = 'GET_CHANNELS_FAILED'
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const GO_TO_DM_CHANNEL = 'GO_TO_DM_CHANNEL';
export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL';
export const CHANGE_GROUP_INFO = 'CHANGE_GROUP_INFO';
export const CHANNEL_ID = 'CHANNEL_ID';
export const CHANGE_CHANNEL_INFO = 'CHANGE_CHANNEL_INFO';
export const GET_MESSAGE_CONTENT = 'GET_MESSAGE_CONTENT';
export const GET_MORE_MESSAGE_CONTENT = 'GET_MORE_MESSAGE_CONTENT';
export const GET_MESSAGE_VIA_PUSHER = 'GET_MESSAGE_VIA_PUSHER';
export const GET_UNREAD_MESSAGE = 'GET_UNREAD_MESSAGE';
export const LEAVE_CHANNEL = 'LEAVE_CHANNEL';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CHANGE_INFO_VIA_PUSHER = 'CHANGE_INFO_VIA_PUSHER';
export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';
export const SET_MESSAGE_REQUEST = 'SET_MESSAGE_REQUEST';
//discussion
export const DISCUSSION_SET_ID = "DISCUSSION_SET_ID";
export const DISCUSSION_GET_MESSAGE_CONTENT = 'DISCUSSION_GET_MESSAGE_CONTENT';
export const DISCUSSION_ADD_MESSAGE_CONTENT = 'DISCUSSION_ADD_MESSAGE_CONTENT';
export const DISCUSSION_GET_MORE_MESSAGE_CONTENT = 'DISCUSSION_GET_MORE_MESSAGE_CONTENT';
export const DISCUSSION_GET_MESSAGE_VIA_PUSHER = 'DISCUSSION_GET_MESSAGE_VIA_PUSHER';
export const DISCUSSION_LEAVE_CHANNEL = 'DISCUSSION_LEAVE_CHANNEL';
export const DISCUSSION_REMOVE_MESSAGE = 'DISCUSSION_REMOVE_MESSAGE';