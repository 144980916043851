import { 
    CREATE_FORUM_REQUEST,
    CREATE_FORUM_SUCCESS,
    CREATE_FORUM_FAIL,
    FORUM_BY_ID_REQUEST,
    FORUM_BY_ID_SUCCESS,
    FORUM_BY_ID_FAIL,
    HANDLE_POLL_OPTION_REQUEST,
    HANDLE_POLL_OPTION_SUCCESS,
    HANDLE_POLL_OPTION_FAIL,
    FORUMS_BY_KEYWORD_REQUEST,
    FORUMS_BY_KEYWORD_SUCCESS,
    FORUMS_BY_KEYWORD_FAIL,
    FORUM_HANDLE_LIKES,
    FORUM_HANDLE_VIEWS,
    FORUM_COMMENT_HANDLE_LIKES,
    CREATE_COMMENT,
    NEW_COMMENT,
    GET_COMMENTS,
    GET_COMMENTS_BY_FORUMID
} from "constants/contributorConstants";
import Cookies from 'js-cookie';
const user = Cookies.getJSON('userInfo') && Cookies.getJSON('userInfo')[0]

const initialState = {
    loading: false,
    forums:[],
    totalCount: '',
    newComments: []
}

function createForumReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_FORUM_REQUEST:
            return { ...state, loading: true };
        case CREATE_FORUM_SUCCESS:
            return { ...state, loading: false};
        case CREATE_FORUM_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function forumByIdReducer( state = initialState, action) {
    let newComments;
    switch (action.type) {
        case FORUM_BY_ID_REQUEST:
            return { ...state, loading: true };
        case FORUM_BY_ID_SUCCESS:
            return { ...state, loading: false, forumById: action.payload };
        case FORUM_BY_ID_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function handlePollOptionReducer(state = initialState, action) {
    switch (action.type) {
        case HANDLE_POLL_OPTION_REQUEST:
            return { ...state, pollLoading: true };
        case HANDLE_POLL_OPTION_SUCCESS:
            return { ...state, pollLoading: false, updatedPollOptions: action.payload.updatedPollOptions, updatedTotalPollCounts: action.payload.totalPollCounts};
        case HANDLE_POLL_OPTION_FAIL:
            return { ...state, pollLoading: false };
        default: return state;
    }
}

function forumsByKeywordReducer( state = initialState, action) {
    switch (action.type) {
        case FORUMS_BY_KEYWORD_REQUEST:
            return { ...state, loading: true };
        case FORUMS_BY_KEYWORD_SUCCESS:
            return { ...state, loading: false, forums: action.payload.forums, totalCount: action.payload.total_count };
        case FORUMS_BY_KEYWORD_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function forumHandleLikesReducer( state = initialState, action ) {
    switch (action.type) {
        case FORUM_HANDLE_LIKES:
            return { ...state, updatedForumById: action.payload.updatedForum}
        default: return state;
    }
}

function forumHandleViewsReducer( state = initialState, action ) {
    switch (action.type) {
        case FORUM_HANDLE_VIEWS:
            return { ...state, updatedForumById: action.payload.updatedForum}
        default: return state;
    }
}

function forumCommentHandleLikesReducer( state = initialState, action ) {
    switch (action.type) {
        case FORUM_COMMENT_HANDLE_LIKES:
            return { ...state, updatedCommentWithForum: action.payload.updatedForum}
        default: return state;
    }
}

function createCommentReducer( state = initialState, action) {
    let newComments;
    switch (action.type) {
        case CREATE_COMMENT:
            return { ...state, updatedComments: action.payload.updatedForum.comments }
        case GET_COMMENTS:
            newComments = JSON.parse(localStorage.getItem('newComments')) || null;
            return { ...state.newComments, newComments: newComments }
        case NEW_COMMENT:
            console.log(user)
            newComments = (state.newComments.filter(p => p.forum_id === action.payload.forum_id && p.user_id === action.payload.user_id).length > 0 || user?.id === action.payload.user_id)
            ? state.newComments 
            : [...state.newComments, {forum_id: action.payload.forum_id, user_id: action.payload.user_id}];
            localStorage.setItem('newComments', JSON.stringify(newComments));
            return { 
                ...state, 
                newComments: newComments
            }
        case GET_COMMENTS_BY_FORUMID:
            newComments = localStorage.getItem('newComments') !== "undefined" ? (JSON.parse(localStorage.getItem('newComments')) || []) : [];
            newComments = state.newComments?.length > 0 ? state.newComments : newComments;
            newComments = newComments?.filter(p => p.forum_id !== action.payload[0].id);
            localStorage.setItem('newComments', JSON.stringify(newComments));
            return { ...state, newComments: newComments };
        default: return state;
    }
}

export {
    createForumReducer,
    forumByIdReducer,
    handlePollOptionReducer,
    forumsByKeywordReducer,
    forumHandleLikesReducer,
    forumHandleViewsReducer,
    forumCommentHandleLikesReducer,
    createCommentReducer
}