import Cookies from 'js-cookie';
import React, { createContext, useReducer } from 'react';

export const AuthContext = createContext();
const userInfo = Cookies.get('userInfo') && JSON.parse(Cookies.get('userInfo'));
const initialState = {
  userInfo: Cookies.get('userInfo')
    ? userInfo
    : null,
  isAuthenticated: userInfo
    ? true : false,
  isPremium: userInfo ? (userInfo[0]?.is_premium ? true : false) : false,
  isAdmin: userInfo ? (userInfo[0]?.is_admin ? true : false) : false,
  isContributor: userInfo ? ((!userInfo[0]?.is_admin && !userInfo[0]?.is_premium) ? true : false) : false,
};

export const AuthProvider = ({ children }) => {

  const [ authState, setAuthState ] = React.useState(initialState)
  
  const value = { authState, setAuthState };
  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
