import requests from '../httpService';
import { notifyError, notifySuccess } from 'utils/toast';
import {
  GET_PREMIUM_GROUPS_REQUEST,
  GET_PREMIUM_GROUPS_SUCCESS,
  GET_PREMIUM_GROUPS_FAIL,
  GET_PREMIUM_GROUP_REQUEST,
  GET_PREMIUM_GROUP_SUCCESS,
  GET_PREMIUM_GROUP_FAIL,
  GET_PREMIUM_EVENTS_REQUEST,
  GET_PREMIUM_EVENTS_SUCCESS,
  GET_PREMIUM_EVENTS_FAIL,
  ADD_PREMIUM_GROUP_EVENT_SUCCESS,
  ADD_PREMIUM_GROUP_EVENT_FAIL,
  GET_PREMIUM_GROUP_EVENTS_SUCCESS,
  GET_PREMIUM_GROUP_EVENTS_FAIL,
  GET_PREMIUM_OTHER_EVENTS_REQUEST,
  GET_PREMIUM_OTHER_EVENTS_SUCCESS,
  GET_PREMIUM_OTHER_EVENTS_FAIL,
  GET_PREMIUM_OTHER_USERS_REQUEST,
  GET_PREMIUM_OTHER_USERS_SUCCESS,
  GET_PREMIUM_OTHER_USERS_FAIL,
  GET_PREMIUM_EVENTS_BY_MONTH_REQUEST,
  GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS,
  GET_PREMIUM_EVENTS_BY_MONTH_FAIL,
  ADD_PREMIUM_GROUP_EVENT_REQUEST,
  GET_CHANNELS_FAILED,
  GET_MESSAGE_VIA_PUSHER,
  REMOVE_MESSAGE,
  GET_UNREAD_DISCUSSION,
  GET_PREMIUM_USER_GROUPS_SUCCESS,
  GET_PREMIUM_USER_GROUPS_REQUEST,
  GET_PREMIUM_USER_GROUPS_FAIL,
} from 'constants/premiumConstants';
import Cookies from 'js-cookie';

const profile_id = Cookies.getJSON('luncsphere_profile') || null;

const fetchGroupsById = (type,body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_USER_GROUPS_REQUEST, payload: { body } })
  await requests.get(`/${type}/fetch-groups/null/null/${body}`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_USER_GROUPS_SUCCESS, payload: res })
        res.length > 0 && res[0].length > 0 && res[0].map(p => {
          window.Echo.channel('channel_discussion' + p.id)
            .listen('PostPublished', post => {
              const group_id = Cookies.getJSON('group_id') || 0;
              if (group_id !== p.id && body?.profile_id !== post.message.profile_id) {
                let data = {
                  ...post.message,
                  profile_id: profile_id,
                  channel_id: p.id
                };

                requests.post(`/premium/discussion/set-unread-messages`, data)
                  .then((res) => {
                    if (res.profile_id !== "duplication")
                      dispatch({ type: GET_UNREAD_DISCUSSION, payload: res });
                  })
                  .catch((err) => {
                    notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
                  })
              }
              if (post.message.message_id) dispatch({ type: REMOVE_MESSAGE, payload: post.message });
              else dispatch({ type: GET_MESSAGE_VIA_PUSHER, payload: {...post.message, type: "premium/discussion"} });
            });
        });
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_USER_GROUPS_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchGroups = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_GROUPS_REQUEST, payload: { body } })
  await requests.get(`/premium/fetch-groups/${body.limit}/${body.searchText ? body.searchText : "null"}/null`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_GROUPS_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_GROUPS_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const handleGroupInfo = (body) => async (dispatch) => {
  await requests.put(`/premium/handle-group-info`, body)
    .then((res) => {
      if (res) {
        notifySuccess("Successfull!");
        dispatch(fetchGroups({limit: -1}));
        body.group_id !== null && dispatch(fetchGroupById(body.group_id))
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchGroupById = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_GROUP_REQUEST, payload: { body } })
  await requests.get(`/premium/${body}/fetch-group-by-id`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_GROUP_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_GROUP_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchEventsByGroupId = (body) => async (dispatch) => {
  await requests.get(`/premium/${body}/fetch-events-by-group-id`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_GROUP_EVENTS_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_GROUP_EVENTS_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchEvents = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_EVENTS_REQUEST, payload: body });
  await requests.get(`/premium/fetch-events/${body}`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_EVENTS_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_EVENTS_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}
const fetchEventsByMonth = (searchMonth) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_EVENTS_BY_MONTH_REQUEST });
  await requests.get(`/premium/fetch-events-by-month/${searchMonth}`)
      .then((res) => {
        if (res) {
          dispatch({ type: GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS, payload: res })
          dispatch({ type: GET_PREMIUM_EVENTS_SUCCESS, payload: res })
        }
      })
      .catch((err) => {
        dispatch({ type: GET_PREMIUM_EVENTS_BY_MONTH_FAIL, payload: err })
        notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      })
}

const createEvent = (body) => async (dispatch) => {
  dispatch({type: ADD_PREMIUM_GROUP_EVENT_REQUEST, payload: body})
  await requests.put(`/premium/create-event`, body)
    .then((res) => {
      if (res) {
        notifySuccess(body.event_id ? "Group event has been successfully updated" : "Group event has been successfully created");
        !body.event_id && dispatch({ type: ADD_PREMIUM_GROUP_EVENT_SUCCESS, payload: res });
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch({ type: ADD_PREMIUM_GROUP_EVENT_FAIL, payload: err });
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchOtherEvents = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_OTHER_EVENTS_REQUEST, payload: body })
  await requests.get(`/premium/${body.searchText}/fetch-other-events`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_OTHER_EVENTS_SUCCESS, payload: res });
        dispatch({ type: GET_PREMIUM_EVENTS_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_OTHER_EVENTS_FAIL });
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const fetchOtherPremiumUsers = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_OTHER_USERS_REQUEST, payload: body })
  await requests.get(`/premium/${body.keyword}/${body.searchText}/fetch-other-users`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_OTHER_USERS_SUCCESS, payload: res });
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_OTHER_USERS_FAIL });
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const deleteEvent = (body) => async () => {
  await requests.delete(`/premium/delete-event/${body.eventId}`).then((res) => res && notifySuccess("The event was successfully deleted")).catch((err) => notifyError("Event failed to delete"))
}


export {
  handleGroupInfo,
  fetchGroupsById,
  fetchGroupById,
  fetchGroups,
  fetchEventsByGroupId,
  fetchEvents,
    fetchEventsByMonth,
  createEvent,
  fetchOtherEvents,
  fetchOtherPremiumUsers,
    deleteEvent,
}