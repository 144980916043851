
export const CHECK_FRIENDSHIP_STATUS = "CHECK_FRIENDSHIP_STATUS";
export const CHECK_FRIENDSHIP_STATUS_FAILED = "CHECK_FRIENDSHIP_STATUS_FAILED";

export const GET_FRIEND = "GET_FRIEND";
export const ADD_FRIEND = "ADD_FRIEND";
export const UPDATE_FRIEND = "UPDATE_FRIEND";

export const GET_FRIEND_REQUEST = "GET_FRIEND_REQUEST";
export const GET_FRIEND_SUCCESS = "GET_FRIEND_SUCCESS";
export const GET_FRIEND_FAILED = "GET_FRIEND_FAILED";

export const ADD_FRIEND_REQUEST = "ADD_FRIEND_REQUEST";
export const ADD_FRIEND_SUCCESS = "ADD_FRIEND_SUCCESS";
export const ADD_FRIEND_FAILED = "ADD_FRIEND_FAILED";

export const GET_INCOMING_FRIEND_REQUESTS = "GET_INCOMING_FRIEND_REQUESTS"
export const GET_INCOMING_FRIEND_REQUESTS_SUCCESS = "GET_INCOMING_FRIEND_REQUESTS_SUCCESS"
export const GET_INCOMING_FRIEND_REQUESTS_FAILED = "GET_INCOMING_FRIEND_REQUESTS_FAILED"

export const UPDATE_FRIENDSHIP_STATUS_REQUEST = "UPDATE_FRIENDSHIP_STATUS_REQUEST";
export const UPDATE_FRIENDSHIP_STATUS_SUCCESS = "UPDATE_FRIENDSHIP_STATUS_SUCCESS";
export const UPDATE_FRIENDSHIP_STATUS_FAILED = "UPDATE_FRIENDSHIP_STATUS_FAILED";

export const GET_NEWEST_PREMIUM_MEMBERS_REQUEST = "GET_NEWEST_PREMIUM_MEMBERS_REQUEST";
export const GET_NEWEST_PREMIUM_MEMBERS_SUCCESS = "GET_NEWEST_PREMIUM_MEMBERS_SUCCESS";
export const GET_NEWEST_PREMIUM_MEMBERS_FAILED = "GET_NEWEST_PREMIUM_MEMBERS_FAILED";
