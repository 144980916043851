import { 
    CREATE_PROFILE_REQUEST,
    CREATE_PROFILE_SUCCESS,
    CREATE_PROFILE_FAIL,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    CREATE_WHITEPAPER_REQUEST,
    CREATE_WHITEPAPER_SUCCESS,
    CREATE_WHITEPAPER_FAIL,
    GET_WHITEPAPERS_REQUEST,
    GET_WHITEPAPERS_SUCCESS,
    GET_WHITEPAPERS_FAIL,
    ADD_PROFILE,
    REMOVE_PROFILE,
    UPDATE_REMOVE_PROFILE
} from "constants/contributorConstants";

const initialState = {
    loading: false,
    whitepapers: [],
    addProfileId: null,
    removeProfileId: null,
}

function createContributorProfileReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_PROFILE_REQUEST:
            return { ...state, loading: true };
        case CREATE_PROFILE_SUCCESS:
            return { ...state, loading: false};
        case CREATE_PROFILE_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getContributorProfileReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_PROFILE_REQUEST:
            return { ...state, loading: true };
        case GET_USER_PROFILE_SUCCESS:
            return { ...state, loading: false, userProfile: action.payload};
        case GET_USER_PROFILE_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function whitepapersReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_WHITEPAPER_REQUEST:
            return { ...state, loading: true };
        case CREATE_WHITEPAPER_SUCCESS:
            return { ...state, loading: false, whitepapers: [...state.whitepapers, action.payload]};
        case CREATE_WHITEPAPER_FAIL:
            return { ...state, loading: false };
        case GET_WHITEPAPERS_REQUEST:
            return { ...state, loading: true };
        case GET_WHITEPAPERS_SUCCESS:
            return { ...state, loading: false, whitepapers: action.payload};
        case GET_WHITEPAPERS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function manageProfileReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PROFILE:
            return { ...state, addProfileId: action.payload }
        case REMOVE_PROFILE:
            return { ...state, removeProfileId: action.payload }
        case UPDATE_REMOVE_PROFILE:
            return { ...state, removeProfileId: action.payload, addProfileId: action.payload }
        default: return state;
    }
}
export {
    createContributorProfileReducer,
    manageProfileReducer,
    getContributorProfileReducer,
    whitepapersReducer
}