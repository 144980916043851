import { notifySuccess } from 'utils/toast';
import {
    GET_PREMIUM_GROUPS_REQUEST,
    GET_PREMIUM_GROUPS_SUCCESS,
    GET_PREMIUM_GROUPS_FAIL,
    GET_PREMIUM_USER_GROUPS_REQUEST,
    GET_PREMIUM_USER_GROUPS_SUCCESS,
    GET_PREMIUM_USER_GROUPS_FAIL,
    GET_PREMIUM_GROUP_REQUEST,
    GET_PREMIUM_GROUP_SUCCESS,
    GET_PREMIUM_GROUP_FAIL,
    GET_PREMIUM_EVENTS_REQUEST,
    GET_PREMIUM_EVENTS_SUCCESS,
    GET_PREMIUM_EVENTS_FAIL,
    GET_PREMIUM_GROUP_EVENTS_SUCCESS,
    GET_PREMIUM_GROUP_EVENTS_FAIL,
    ADD_PREMIUM_GROUP_EVENT_SUCCESS,
    GET_PREMIUM_OTHER_EVENTS_REQUEST,
    GET_PREMIUM_OTHER_EVENTS_SUCCESS,
    GET_PREMIUM_OTHER_EVENTS_FAIL,
    GET_PREMIUM_OTHER_USERS_REQUEST,
    GET_PREMIUM_OTHER_USERS_SUCCESS,
    GET_PREMIUM_OTHER_USERS_FAIL,
    GET_UNREAD_DISCUSSION,
    PREMIUM_JOIN_GROUP_REQUEST,
    PREMIUM_JOIN_GROUP_SUCCESS,
    PREMIUM_JOIN_GROUP_FAIL,
    PREMIUM_LEAVE_GROUP_FAIL,
    PREMIUM_LEAVE_GROUP_REQUEST,
    PREMIUM_LEAVE_GROUP_SUCCESS,
    GET_PREMIUM_EVENTS_BY_MONTH_REQUEST,
    GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS,
    GET_PREMIUM_EVENTS_BY_MONTH_FAIL, ADD_PREMIUM_GROUP_EVENT_REQUEST, SET_UNREAD_DISCUSSION,
} from "constants/premiumConstants";
import Cookies from 'js-cookie';

const initialState = {
    loading: false,
    whitepapers: [],
    addMeetId: null,
    removeMeetId: null,
    premiumGroupEvents: [],
    totalUnread: 0,
    premiumGroups: [],
    premiumGroup: null,
    userGroups: [],
    newDiscussion: null,
}

function getPremiumGroupsReducer(state = initialState, action) {
    const profile_id = Cookies.getJSON('luncsphere_profile') || null;
    let userGroups, newDiscussion;

    switch (action.type) {
        case GET_PREMIUM_GROUPS_REQUEST:
            return { ...state, loading: true };
        case GET_PREMIUM_GROUPS_SUCCESS:
            return { ...state, loading: false, premiumGroups: action.payload };
        case GET_PREMIUM_GROUPS_FAIL:
            return { ...state, loading: false };
        case GET_PREMIUM_USER_GROUPS_REQUEST:
            return { ...state, loading: true};
        case GET_PREMIUM_USER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                userGroups: action.payload,
                newDiscussion: action.payload[0].map(g => g.notifications)[0]?.find(n => n.profile_id === profile_id)?.count || 0 > 0
            };
        case GET_PREMIUM_USER_GROUPS_FAIL:
            return { ...state, loading: false };
        case GET_UNREAD_DISCUSSION:
            notifySuccess(`You have ${action.payload.count || 0} unread messages in ${action.payload.group.name} group!`)
            return {
                ...state,
                userGroups: (Array.isArray(state.userGroups) && state.userGroups.length > 0) ? [state.userGroups[0].map(p => p.id === action.payload.group.id ? {...p, notifications: p.notifications.map(n => n.profile_id === profile_id ? {...n, count: action.payload.count} : n)} : p)] : [],
                newDiscussion: true
            }
        default: return state;    
    }
}

function getPremiumGroupReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_GROUP_REQUEST:
            return { ...state, loading: true }
        case GET_PREMIUM_GROUP_SUCCESS:
            Cookies.set('group_id', action.payload.group.id);
            return { ...state, loading: false, premiumGroup: action.payload };
        case GET_PREMIUM_GROUP_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getPremiumEventsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_EVENTS_REQUEST:
            return { ...state, loading: true, filterInfo: action.payload }
        case GET_PREMIUM_EVENTS_SUCCESS:
            return { ...state, loading: false, premiumEvents: action.payload };
        case GET_PREMIUM_EVENTS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getPremiumEventsByMonthReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_EVENTS_BY_MONTH_REQUEST:
            return { ...state, loading: true, filterInfo: action.payload }
        case GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS:
            return { ...state, loading: false, premiumEvents: action.payload };
        case GET_PREMIUM_EVENTS_BY_MONTH_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getPremiumGroupEventsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PREMIUM_GROUP_EVENT_REQUEST:
            return {...state,loading: true}
        case GET_PREMIUM_GROUP_EVENTS_SUCCESS:
            return { ...state, loading: false, premiumGroupEvents: action.payload };
        case GET_PREMIUM_GROUP_EVENTS_FAIL:
            return { ...state, loading: false };
        case ADD_PREMIUM_GROUP_EVENT_SUCCESS:
            return { ...state,loading: false, premiumGroupEvents: {events : [...state.premiumGroupEvents?.events, action.payload?.event] }}
        default: return state;
    }
}

function getPremiumOtherEventsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_OTHER_EVENTS_REQUEST:
            return { ...state, loading: true };
        case GET_PREMIUM_OTHER_EVENTS_SUCCESS:
            return { ...state, loading: false, premiumOtherEvents: action.payload };
        case GET_PREMIUM_OTHER_EVENTS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getPremiumOtherUsersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_OTHER_USERS_REQUEST:
            return { ...state, loading: true, filterInfo: action.payload };
        case GET_PREMIUM_OTHER_USERS_SUCCESS:
            return { ...state, loading: false, prmeiumOtherUsers: action.payload };
        case GET_PREMIUM_OTHER_USERS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function premiumJoinGroup(state = initialState, action) {
    switch (action.type) {
        case PREMIUM_JOIN_GROUP_REQUEST:
            return { ...state, loading: true }
        case PREMIUM_JOIN_GROUP_SUCCESS:
            return { ...state, loading: false, premium: action.payload }

        case PREMIUM_JOIN_GROUP_FAIL:
            return { ...state, loading: false }
        default:
            return state;
    }
}
function premiumLeaveGroup(state = initialState, action) {
    switch (action.type) {
        case PREMIUM_LEAVE_GROUP_REQUEST:
            return { ...state, loading: true }
        case PREMIUM_LEAVE_GROUP_SUCCESS:
            return { ...state, loading: false, premium: action.payload }

        case PREMIUM_LEAVE_GROUP_FAIL:
            return { ...state, loading: false }
        default:
            return state;
    }
}


export {
    getPremiumGroupsReducer,
    getPremiumGroupReducer,
    getPremiumEventsReducer,
    getPremiumEventsByMonthReducer,
    getPremiumGroupEventsReducer,
    getPremiumOtherEventsReducer,
    getPremiumOtherUsersReducer,
    premiumJoinGroup,
    premiumLeaveGroup,
}