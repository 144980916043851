import requests from '../httpService';
import {
  GET_CHANNELS,
  GET_CHANNELS_FAILED,
  ADD_CHANNEL,
  CHANGE_CHANNEL_INFO,
  GET_MESSAGE_CONTENT,
  GET_MESSAGE_VIA_PUSHER,
  LEAVE_CHANNEL,
  REMOVE_MESSAGE,
  GET_MORE_MESSAGE_CONTENT,
  RECEIVE_CHANNEL,
  GO_TO_DM_CHANNEL,
  CHANGE_INFO_VIA_PUSHER,
  SET_CHANNEL_ID,
  DISCUSSION_SET_ID,
  GET_UNREAD_MESSAGE,
  ADD_MESSAGE,
  DISCUSSION_ADD_MESSAGE_CONTENT,
  SET_MESSAGE_REQUEST
} from 'constants/contributorConstants';
import { notifyError, notifyInform, notifySuccess } from 'utils/toast';
import Cookies from 'js-cookie';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {
    FRIEND_STATUS_PENDING, FRIEND_STATUS_REQUEST,
    GET_PREMIUM_USER_GROUPS_SUCCESS,
    JOIN_GROUP,
    PREMIUM_JOIN_GROUP_REQUEST,
    PREMIUM_JOIN_GROUP_SUCCESS,
    PREMIUM_LEAVE_GROUP_SUCCESS
} from 'constants/premiumConstants';
import { ADD_FRIEND_FAIL, ADD_FRIEND_FAILED, ADD_FRIEND_SUCCESS, CHECK_FRIENDSHIP_STATUS, CHECK_FRIENDSHIP_STATUS_FAILED, GET_INCOMING_FRIEND_REQUESTS_FAILED, GET_INCOMING_FRIEND_REQUESTS_SUCCESS, UPDATE_FRIENDSHIP_STATUS_FAILED, UPDATE_FRIENDSHIP_STATUS_SUCCESS } from 'constants/commonConstants';
import { fetchGroupsById } from 'actions/premium/meetAction';
const userInfo = Cookies.getJSON('userInfo') && Cookies.getJSON('userInfo')[0]
const profile_id = Cookies.getJSON('luncsphere_profile') || null;
export const goToDmChannel = (data) => async (dispatch) => {
  await requests.post(`/${data.type}/add-channel`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: GO_TO_DM_CHANNEL, payload: res.channel_id })
      }
    })
    .catch((err) => {
    })
}

export const addMemberOnChannel = (data) => async (dispatch) => {
  await requests.post(`/contributors/add-member-on-channel`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: CHANGE_CHANNEL_INFO, payload: res.updated_channel })
        notifySuccess(res.message)
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

export const getChannelsById = (body) => async (dispatch) => {
  dispatch({ type: SET_MESSAGE_REQUEST });
  await requests.get(`/${body.type}/${body.profile_id}/get-channels`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_CHANNELS, payload: res });
        res.length > 0 && res[0].length > 0 && res[0].map(p => {
          window.Echo.channel('channel' + p.id)
            .listen('PostPublished', (post) => {
              const channel_id = Cookies.getJSON('channel_id') ? Cookies.getJSON('channel_id') : 0;
              if (channel_id !== p.id && body?.profile_id !== post?.message?.profile_id) {
                console.log(channel_id, p.id, post.message.profile_id, profile_id)
                let data = post.message;
                data.profile_id = body?.profile_id;

                requests.post(`/${body.type}/set-unread-messages`, data)
                  .then((res) => {
                    if (res.profile_id !== "duplication")
                      dispatch({ type: GET_UNREAD_MESSAGE, payload: res });
                  })
                  .catch((err) => {
                    notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
                    dispatch({ type: GET_CHANNELS_FAILED, payload: err })
                  })
              }

              if (post.message.message_id) {
                dispatch({ type: REMOVE_MESSAGE, payload: post.message });
              }else{
                dispatch({ type: GET_MESSAGE_VIA_PUSHER, payload: post.message });
              }

              // if (!('Notification' in window)) {
              //   // alert('Web Notification is not supported');
              //   return;
              // }
            });
        });

        // setting
        window.Echo.channel('channel_setting')
          .listen('PostPublished', post => {
            // if (!('Notification' in window)) {
            //   // alert('Web Notification is not supported');
            //   return;
            // }
            dispatch({ type: CHANGE_INFO_VIA_PUSHER, payload: post.message.channel })
          });

        window.Echo.channel('channel_invite')
          .listen('PostPublished', post => {
            // if (!('Notification' in window)) {
            //   // alert('Web Notification is not supported');
            //   return;
            // }
            if (post?.message?.profiles?.includes(profile_id)) {
              dispatch({ type: RECEIVE_CHANNEL, payload: post.message.new_channel_id })
              window.Echo.channel('channel' + post.message.new_channel_id)
                .stopListening('PostPublished');
              window.Echo.channel('channel' + post.message.new_channel_id)
                .listen('PostPublished', message => {
                  // if (!('Notification' in window)) {
                  //   // alert('Web Notification is not supported');
                  //   return;
                  // }
                  dispatch({ type: GET_MESSAGE_VIA_PUSHER, payload: message.message });
                });
            }
          });

      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const addChannel = (data) => async (dispatch) => {
  await requests.post(`/${data.type}/add-channel`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: ADD_CHANNEL, payload: res })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      // dispatch({ type:GET_CHANNELS_FAILED, payload:err })
    })
}

export const changeGroupInfo = (body) => async (dispatch) => {
  await requests.post(`/contributors/change-channel-info`, body)
    .then((res) => {
      if (res) {
        notifySuccess(res.message)
        dispatch({ type: CHANGE_CHANNEL_INFO, payload: res.updated_channel })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const sendMessageAction = (body) => async (dispatch) => {
  dispatch({ type: SET_MESSAGE_REQUEST });
  await requests.post(`/${body.type}/save-message-content`, body)
    .then((res) => {
      if (res) {
        // dispatch({ type: ADD_MESSAGE, payload: res })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err });
    })
}

export const sendMessageTest = (body) => async (dispatch) => {
  await requests.post(`/contributors/send-message`, body)
    .then((res) => {
      if (res) {
        // notifySuccess(res.message)
        // dispatch({ type: ADD_MESSAGE, payload: res })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const getMessageContent = (body) => async (dispatch) => {
  dispatch({ type:  SET_MESSAGE_REQUEST });
  await requests.get(`/${body.type}/${body.channel_id}/${body.profile_id}/get-message-content`)
    .then((res) => {
      if (res) {
        const data = {
          channel_id: body.channel_id,
          messageContent: res
        }
        dispatch({ type: GET_MESSAGE_CONTENT, payload: data });
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const setChannelId = (body) => async (dispatch) => {
  dispatch({ type: SET_CHANNEL_ID, payload: body.id });
}

export const getMoreMessageContent = (data) => async (dispatch) => {
  await requests.post(`/${data.type}/get-more-message-content`, data)
    .then((res) => {
      if (res?.data) {
        dispatch({ type: GET_MORE_MESSAGE_CONTENT, payload: res });
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const leaveChannel = (body) => async (dispatch) => {
  await requests.post(`/${body.type}/leave-channel`, body)
    .then((res) => {
      if (res) {
        // notifySuccess(res.message)
        dispatch({ type: LEAVE_CHANNEL, payload: res.channel_id })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const removeMessage = (body) => async (dispatch) => {
  await requests.post(`/${body.type}/remove-message`, body)
    .then((res) => {
      if (res) {
        // notifySuccess(res.message)
        // dispatch({ type: REMOVE_MESSAGE, payload: body })
      }
    })
    .catch((err) => {
      notifyError(err.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: GET_CHANNELS_FAILED, payload: err })
    })
}

export const joinGroup = (data) => async (dispatch) => {
  await requests.post(`/premium/join-group`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: PREMIUM_JOIN_GROUP_SUCCESS, payload: res })
        notifySuccess(res.message)
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}
export const leaveGroup = (data) => async (dispatch) => {
  await requests.post(`/premium/leave-group`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: PREMIUM_LEAVE_GROUP_SUCCESS, payload: res })
        notifySuccess(res.message)
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

//discussion
export const setDiscussionID = (id) => async (dispatch) => {
  dispatch({ type: DISCUSSION_SET_ID, payload: id });
  window.Echo.channel('channel_discussion' + id)
    .listen('PostPublished', post => {
      // if (!('Notification' in window)) {
      //   // alert('Web Notification is not supported');
      //   return;
      // }
      if (post?.message?.message_id) {
        dispatch({ type: REMOVE_MESSAGE, payload: post.message });
        return;
      }
      post.message.type = "premium/discussion";
      dispatch({ type: GET_MESSAGE_VIA_PUSHER, payload: post.message });
    });
}

export const addFriend = (data) => async (dispatch) => {
  await requests.post(`/${data.type}/add-friend`, data)
    .then((res) => {
      if (res) {
        // request pending
        res.status === null && notifyInform(res.message)
        // request accepted
        res.status && notifySuccess(res.message)
        // request rejected
        res.status === false && notifyError(res.message)
        dispatch({ type: ADD_FRIEND_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: ADD_FRIEND_FAILED, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

export const getIncomingFriendRequests = (data) => async (dispatch) => {
  await requests.get(`/${data.type}/${data.profile_id}/get-incoming-friend-requests`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_INCOMING_FRIEND_REQUESTS_SUCCESS, payload: res })
      }

    }).catch((err) => {
      dispatch({ type: GET_INCOMING_FRIEND_REQUESTS_FAILED, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    });
}

export const updateFriendshipStatus = (data) => async (dispatch) => {
  await requests.put(`/${data.type}/update-friendship-status`, data)
    .then((res) => {
      if (res) {
        dispatch({ type: UPDATE_FRIENDSHIP_STATUS_SUCCESS, payload: res })
      }
    }).catch((err) => {
      dispatch({ type: UPDATE_FRIENDSHIP_STATUS_FAILED, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

export const checkFriendshipStatus = ({type,data}) => async (dispatch) => {
  await requests.post(`/${type}/check-friendship-status`,data)
    .then(async (res) => {
      await dispatch({ type: CHECK_FRIENDSHIP_STATUS, payload: res?.friendship?.status === 1  ? true : res?.friendship?.status === 0 ? false : res?.friendship?.status == null && res?.friendship?.friend_id  ? FRIEND_STATUS_PENDING : FRIEND_STATUS_REQUEST })
    }).catch((err) => {
      dispatch({ type: CHECK_FRIENDSHIP_STATUS_FAILED, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

