import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { BiLogOut } from 'react-icons/bi';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { FaUserEdit } from 'react-icons/fa';
import { BsDownload, BsChevronDown, BsChevronUp } from 'react-icons/bs';

import { AuthContext } from 'context/authContext';
import logodark from '../../assets/real_images/logo/logo-blackfont1.png'
import logodark2x from '../../assets/real_images/logo/logo-blackfont1.png'
import logoWhiteFont from '../../assets/real_images/logo/logo-whitefont1.png'
import enUS from '../../data/en-US'
import { useLanguageContext } from "../../context/languageContext";
import Button from '../buttons/Button';
import ClickAwayListener from 'components/layouts/ClickAwayListener';
import { contributorLogout } from 'actions/contributor/contributorAuthActions';
import { notifyInform } from 'utils/toast';
import {getProfileById} from "../../actions/premium/profileAction";

const Header = ({ active }) => {
    const { pathname } = useLocation();
    const profile_id = Cookies.getJSON('luncsphere_profile') || null;
    const user = Cookies.getJSON('userInfo') ? Cookies.getJSON('userInfo')[0] : null;
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { language } = useLanguageContext();

    const { authState, setAuthState } = useContext(AuthContext);
    const { isAuthenticated, isContributor } = authState;

    
    const [menus, setMenus] = useState([]);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    
    const {isNftHolder} = Cookies.getJSON('userInfo') && Cookies.getJSON('userInfo') || [];

    const [profileId, setProfileId] = useState(Cookies.getJSON('luncsphere_profile') && Cookies.getJSON('luncsphere_profile'));

    const [authUser, setAuthUser] = useState(user)
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const { totalUnread } = useSelector(state => state.message);
    const { newDiscussion } = useSelector(state => state.premiumGroups);
    
    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        // isContributor && user && dispatch(getChannelsById(0));

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleAvatarClick = () => {
        setIsUserMenuOpen(true);
    };

    const handleLogout = async () => {
        dispatch(contributorLogout()).then(navigate('/'))
        setAuthState({
            userInfo: null,
            isAuthenticated: false
        })
    }

    const info = {
        'en-US': enUS
    }

    useEffect(() => {
        setMenus(info[language].homepage.header_menu.header_menu_1)
    }, [])

    const headerRef = useRef(null)
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    // If the user logged in fetch user detail info


    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        if (header && scrollTop) {
            scrollTop >= 160 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
            scrollTop >= 160 ? header.classList.add('is-small') : header.classList.remove('is-small');
        }
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = (index,isPaid) => {
        setActiveIndex(index);
        if (menus[index].links === "#") {
            notifyInform("Coming soon. Not Available for now.")
        }
    };

    const handleProfileChange = (profileId,type) => {
        Cookies.set('luncsphere_profile', profileId);
        dispatch(getProfileById( profileId,type));
    }

    const handleMessagePage = () => {
        setTimeout(() => {
            let userType = user?.is_premium ? 'premium' : 'contributor'
            navigate(`/${userType}/message`)
            console.log(`/${userType}/message`)
        }, 200)
    }
    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="wrap-box flex justify-center">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo d-flex">
                                            <img className='logo-white w-100 h-100 mr-3' id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="luncsphere" />
                                            <img className='logo-white h-100 mt-2' id="logo_header" src={logoWhiteFont} srcSet={`${logoWhiteFont}`} alt="luncsphere" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                <nav id="main-nav" className="main-nav" ref={menuLeft} >
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus && menus.map((data, index) => (
                                                !data.permission ?
                                                    <li key={index} onClick={() => handleOnClick(index, true)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''}`}   >
                                                        <Link to={data.links} className={`font-face-pop ${active === data.name ? 'header-menu-active' : ''} w-full`}>{data.name}</Link>
                                                    </li>
                                                    :
                                                    user && isAuthenticated && data.is_premium === Boolean(user?.is_premium || false) &&
                                                    <li key={index} onClick={() => handleOnClick(index,user?.is_paid)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''}`}   >
                                                        <Link to={user?.is_paid || !user.is_premium ? data.links : ''} className={`font-face-pop ${active === data.name ? 'header-menu-active' : ''} w-full h-auto relative`}>
                                                            {data.name}
                                                            {data.links === "/premium/meet" && newDiscussion && 
                                                                <div className=' absolute top-0 -right-3 w-3 h-3 rounded-full unread-message'></div>
                                                            }
                                                        </Link>
                                                    </li>
                                            ))
                                        }
                                    </ul>
                                </nav>
                                <div className={`flat-search-btn flex optional-login ${user?.email && 'optional-login-border'}`}>
                                    {
                                        (authUser && isAuthenticated) ? (
                                            <div className='header-loggedin-wrapper'>
                                                <span className='header-loggedin-user-welcome'>Hi! {authUser?.profiles?.filter(profile => profile.id === profileId)[0]?.alias ? authUser?.profiles?.filter(profile => profile.id === profileId)[0]?.alias.slice(1) : ''}</span>
                                                <div className='header-loggedin-user-avatar flex cursor-pointer relative' onClick={handleAvatarClick} >
                                                    <img src={process.env.REACT_APP_S3_BASE_URL + authUser?.profiles?.filter(profile => profile.id === profileId)[0]?.avatar} alt="User avatar" />
                                                    {
                                                        isUserMenuOpen ?
                                                            <AiFillCaretUp className='my-auto ml-3 text-white' />
                                                            :
                                                            <AiFillCaretDown className='my-auto ml-3 text-white' />
                                                    }
                                                    {totalUnread > 0 &&
                                                        <div className=' absolute bottom-0 right-5 w-5 h-5 rounded-full flex justify-center items-center unread-message'></div>
                                                    }
                                                </div>
                                                {
                                                    isUserMenuOpen && (
                                                        <ClickAwayListener handleClickAway={() => setIsUserMenuOpen(!isUserMenuOpen)}>
                                                            <div className="absolute z-10 right-0 w-80 pt-3 bg-white rounded-md shadow-md avatar-dropdown-menu">
                                                                <div className='px-3 py-2 flex justify-between cursor-pointer border-b-2 border-solid' onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}>
                                                                    <div>
                                                                        {authUser.profiles.length === 1 ?
                                                                            `My Profile`
                                                                            : `My Profiles`
                                                                        }
                                                                    </div>
                                                                    {
                                                                        isProfileMenuOpen ? <BsChevronUp /> : <BsChevronDown />
                                                                    }
                                                                </div>
                                                                {
                                                                    isProfileMenuOpen ?
                                                                        authUser && authUser.profiles?.map((profile,i) =>
                                                                            <Link to={authUser?.roles[i].role == 'Contributor' ? `/contributor/dashboard/${profile.id}` : `/premium/${profile.id}/profile`} key={profile.id} onClick={() => handleProfileChange(profile.id,authUser?.roles[i].role == 'Contributor' ? 'contributors' : 'premium')}>
                                                                                <div className='pb-3 pt-3 pl-5 text-center flex profile-button-wrapper font-medium cursor-pointer bg-gray-200 hover:bg-gray-300 transition-all duration-300 overflow-hidden'>
                                                                                    <FaUserEdit className='my-auto mx-3' />
                                                                                    {
                                                                                        profile.alias ? profile.alias :
                                                                                            authUser.roles.filter(role => role.id === profile.role_id)[0]?.type
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                        )
                                                                        : ''
                                                                }
                                                                {totalUnread > 0 &&
                                                                    <div onClick={handleMessagePage} className='text-center pb-3 pt-3 flex logout-button-wrapper font-medium cursor-pointer hover:bg-gray-200 transition-all duration-300'>
                                                                        You have {totalUnread} unread message!
                                                                    </div>
                                                                }
                                                                <div onClick={handleLogout} className='text-center pb-3 pt-3 flex logout-button-wrapper font-medium cursor-pointer hover:bg-gray-200 transition-all duration-300'>
                                                                    <BiLogOut className='my-auto mx-3' />
                                                                    Logout
                                                                </div>
                                                            </div>
                                                        </ ClickAwayListener>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <>
                                                <nav>
                                                    <ul id="menu-primary-menu">
                                                        <li>
                                                            <Link to="/contributor" className={`header-contributors font-face-pop ${active === 'Contributors' && 'header-menu-active'}`}>Contributors</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                <div className="sc-btn-top" id="site-header">
                                                    <Link to="/premium" className='font-face-pop'>
                                                        <Button
                                                            width={150}
                                                            height={50}
                                                            text={'Premium Users'}
                                                            borderRadius={5}
                                                            theme='gradient'
                                                        />
                                                    </Link>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deferredPrompt && (
                <div className='bg-white mt-10 pwa-install-btn-wrapper font-face-pop cursor-pointer max-md:mt-40' onClick={() => deferredPrompt.prompt()}>
                    <div id="installApp" className='flex gap-3'>
                        <BsDownload />
                        <div>
                            Install App
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
}

export default Header;
