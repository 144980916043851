import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import {
  contributorLoginReducer,
  contributorRegisterReducer,
  forgotPasswordReducer,
  sendResetPasswordReducer,
  resetPasswordReducer,
  getContributorRolesReducer,
  checkEmailExistsReducer, checkWalletAddressExistsReducer
} from './reducers/contributor/contributorAuthReducer';
import {
  createContributorProfileReducer,
  manageProfileReducer,
  getContributorProfileReducer,
  whitepapersReducer

} from './reducers/contributor/contributorProfileReducer';
import {
  getContributorsByKeywordReducer,
  getTopForumsByKeywordReducer,
} from './reducers/contributor/contributorCommunityReducer';
import {
  getContributorsReducer,
  getProfileByIdReducer,
  getFeaturedContributorReducer
} from './reducers/contributor/contributorDirectoryReducer'
import {
  contributorRolesReducer
} from './reducers/homeReducer';
import {
  createEventReducer,
  getEventsCategoriesReducer,
  getEventsByMonthReducer,
  eventByIdReducer,
  eventNearestReducer
} from './reducers/contributor/contributorEventReducer'
import {
  createNewsReducer,
  newsCategoriesReducer,
  newsReducer,
  newsByIdReducer
} from './reducers/contributor/contributorNewsReducer'
import {
  createForumReducer,
  forumByIdReducer,
  handlePollOptionReducer,
  forumsByKeywordReducer,
  forumHandleLikesReducer,
  forumCommentHandleLikesReducer,
  createCommentReducer
} from 'reducers/contributor/contributorForumReducer';
import {
  messageReducer
} from './reducers/common/messageReducer'
import {
  premiumHomeReducer
} from './reducers/premium/homeReducer';
import {
  premiumRegisterReducer,
  premiumLoginReducer,
} from './reducers/premium/authReducer';
import {
  getPremiumProfileReducer
} from './reducers/premium/profileReducer';

import { blogsByIdReducer, blogsReducer, createBlogsReducer, updateBlogLikesReducer, createBlogCommentReducer,blogCommentsByIdReducer } from './reducers/contributor/contributorBlogsReducer';
import {
  getPremiumGroupsReducer,
  getPremiumGroupReducer,
  getPremiumEventsReducer,
  getPremiumGroupEventsReducer,
  getPremiumOtherEventsReducer,
  getPremiumOtherUsersReducer,
  premiumJoinGroup,
  premiumLeaveGroup, getPremiumEventsByMonthReducer
} from 'reducers/premium/meetReducer';
import {getNewestPremiumMembers} from "./reducers/common/homeReducer";
import mapReducer from "./reducers/premium/googleMapsReducer";

const userInfo = Cookies.getJSON('userInfo') || null;

const initialState = {
  userSignin: { userInfo },
};
const reducer = combineReducers({
  newestPremiumMembers: getNewestPremiumMembers,
  checkEmail: checkEmailExistsReducer,
  checkWallet: checkWalletAddressExistsReducer,
  userSignin: contributorLoginReducer,
  userRegister: contributorRegisterReducer,
  forgotPassword: forgotPasswordReducer,
  sendResetPassword: sendResetPasswordReducer,
  resetPassword: resetPasswordReducer,
  createProfile: createContributorProfileReducer,
  manageProfile: manageProfileReducer,
  getProfile: getContributorProfileReducer,
  whitepapers: whitepapersReducer,
  getContributorRoles: getContributorRolesReducer,
  contributorsByKeyword: getContributorsByKeywordReducer,
  topForumsByKeyword: getTopForumsByKeywordReducer,
  createEvent: createEventReducer,
  createBlogs: createBlogsReducer,
  createBlogComment: createBlogCommentReducer,
  createNews: createNewsReducer,
  contributorRoles: contributorRolesReducer,
  contributors: getContributorsReducer,
  profileById: getProfileByIdReducer,
  eventsCategories: getEventsCategoriesReducer,
  eventsByMonth: getEventsByMonthReducer,
  newsCategories: newsCategoriesReducer,
  blogs: blogsReducer,
  news: newsReducer,
  updateLikesBlog: updateBlogLikesReducer,
  blogsById: blogsByIdReducer,
  blogCommentsById: blogCommentsByIdReducer,
  newsById: newsByIdReducer,
  eventById: eventByIdReducer,
  featuredContributor: getFeaturedContributorReducer,
  createForumReducer: createForumReducer,
  forumById: forumByIdReducer,
  handlePollOption: handlePollOptionReducer,
  forumsByKeyword: forumsByKeywordReducer,
  forumHandleLikes: forumHandleLikesReducer,
  forumCommentHandleLikes: forumCommentHandleLikesReducer,
  createComment: createCommentReducer,
  nearestEvents: eventNearestReducer,
  premiumHome: premiumHomeReducer,
  premiumRegister: premiumRegisterReducer,
  premiumSignin: premiumLoginReducer,
  premiumProfile: getPremiumProfileReducer,
  premiumGroups: getPremiumGroupsReducer,
  premiumGroup: getPremiumGroupReducer,
  premiumEvents: getPremiumEventsReducer,
  premiumEventsByMonth: getPremiumEventsByMonthReducer,
  premiumGroupEvents: getPremiumGroupEventsReducer,
  premiumOtherEvents: getPremiumOtherEventsReducer,
  prmeiumOtherUsers: getPremiumOtherUsersReducer,
  message: messageReducer,
  premiumJoinGroup: premiumJoinGroup,
  premiumLeaveGroup: premiumLeaveGroup,
  googleMaps: mapReducer,
});

const composeEnhancer = process.env.REACT_APP_NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
