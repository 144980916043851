export const GET_FREELANCER_SERVICES = 'GET_FREELANCER_SERVICES';
export const REGISTER_PREMIUM = 'REGISTER_PREMIUM';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const PREMIUM_LOGIN_REQUEST = "PREMIUM_LOGIN_REQUEST";
export const PREMIUM_LOGIN_SUCCESS = "PREMIUM_LOGIN_SUCCESS";
export const PREMIUM_LOGIN_FAIL = "PREMIUM_LOGIN_FAIL";
export const PREMIUM_LOGOUT = "PREMIUM_LOGOUT";
// PREMIUM PROFILE
export const GET_PREMIUM_PROFILE_REQUEST = "GET_PREMIUM_PROFILE_REQUEST";
export const GET_PREMIUM_PROFILE_SUCCESS = "GET_PREMIUM_PROFILE_SUCCESS";
export const GET_PREMIUM_PROFILE_FAIL = "GET_PREMIUM_PROFILE_FAIL";
export const SUBSCRIPTION_PURCHASE_REQUEST = "SUBSCRIPTION_PURCHASE_REQUEST";
export const SUBSCRIPTION_PURCHASE_SUCCESS = "SUBSCRIPTION_PURCHASE_SUCCESS";
export const SUBSCRIPTION_PURCHASE_FAIL = "SUBSCRIPTION_PURCHASE_FAIL";

//PREMIUM MESSAGE
export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_CHANNELS_BY_ID = 'GET_CHANNELS_BY_ID';
export const GET_CHANNELS_FAILED = 'GET_CHANNELS_FAILED'
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const GO_TO_DM_CHANNEL = 'GO_TO_DM_CHANNEL';
export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL';
export const CHANGE_GROUP_INFO = 'CHANGE_GROUP_INFO';
export const CHANGE_CHANNEL_INFO = 'CHANGE_CHANNEL_INFO';
export const GET_MESSAGE_CONTENT = 'GET_MESSAGE_CONTENT';
export const SEND_MESSAGE_CONTENT = 'SEND_MESSAGE_CONTENT';
export const GET_MORE_MESSAGE_CONTENT = 'GET_MORE_MESSAGE_CONTENT';
export const GET_MESSAGE_VIA_PUSHER = 'GET_MESSAGE_VIA_PUSHER';
export const LEAVE_CHANNEL = 'LEAVE_CHANNEL';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CHANGE_INFO_VIA_PUSHER = 'CHANGE_INFO_VIA_PUSHER';
export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';

//PREMIUM MEET
export const GET_PREMIUM_GROUPS_REQUEST = 'GET_PREMIUM_GROUPS_REQUEST';
export const GET_PREMIUM_GROUPS_SUCCESS = 'GET_PREMIUM_GROUPS_SUCCESS';
export const GET_PREMIUM_GROUPS_FAIL = 'GET_PREMIUM_GROUPS_FAIL';
export const GET_PREMIUM_USER_GROUPS_REQUEST = 'GET_PREMIUM_USER_GROUPS_REQUEST';
export const GET_PREMIUM_USER_GROUPS_SUCCESS = 'GET_PREMIUM_USER_GROUPS_SUCCESS';
export const GET_PREMIUM_USER_GROUPS_FAIL = 'GET_PREMIUM_USER_GROUPS_FAIL';
export const GET_UNREAD_DISCUSSION = 'GET_UNREAD_DISCUSSION';
export const SET_UNREAD_DISCUSSION = 'SET_UNREAD_DISCUSSION';

export const GET_PREMIUM_GROUP_REQUEST = "GET_PREMIUM_GROUP_REQUEST"
export const GET_PREMIUM_GROUP_SUCCESS = 'GET_PREMIUM_GROUP_SUCCESS';
export const GET_PREMIUM_GROUP_FAIL = 'GET_PREMIUM_GROUP_FAIL';

export const PREMIUM_JOIN_GROUP_REQUEST = "PREMIUM_JOIN_GROUP_REQUEST"
export const PREMIUM_JOIN_GROUP_SUCCESS = 'PREMIUM_JOIN_GROUP_SUCCESS';
export const PREMIUM_JOIN_GROUP_FAIL = 'PREMIUM_JOIN_GROUP_FAIL';

export const PREMIUM_LEAVE_GROUP_REQUEST = "PREMIUM_LEAVE_GROUP_REQUEST"
export const PREMIUM_LEAVE_GROUP_SUCCESS = 'PREMIUM_LEAVE_GROUP_SUCCESS';
export const PREMIUM_LEAVE_GROUP_FAIL = 'PREMIUM_LEAVE_GROUP_FAIL';

export const GET_PREMIUM_EVENTS_REQUEST = 'GET_PREMIUM_EVENTS_REQUEST';
export const GET_PREMIUM_EVENTS_SUCCESS = 'GET_PREMIUM_EVENTS_SUCCESS';
export const GET_PREMIUM_EVENTS_FAIL = 'GET_PREMIUM_EVENTS_FAIL';

export const GET_PREMIUM_EVENTS_BY_MONTH_REQUEST = 'GET_PREMIUM_EVENTS_BY_MONTH_REQUEST';
export const GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS = 'GET_PREMIUM_EVENTS_BY_MONTH_SUCCESS';
export const GET_PREMIUM_EVENTS_BY_MONTH_FAIL = 'GET_PREMIUM_EVENTS_BY_MONTH_FAIL';
export const ADD_PREMIUM_GROUP_SUCCESS = 'ADD_PREMIUM_GROUP_SUCCESS';
export const ADD_PREMIUM_GROUP_FAIL = 'ADD_PREMIUM_GROUP_FAIL';

export const ADD_PREMIUM_GROUP_EVENT_REQUEST = "ADD_PREMIUM_GROUP_EVENT_REQUEST";
export const ADD_PREMIUM_GROUP_EVENT_SUCCESS = 'ADD_PREMIUM_GROUP_EVENT_SUCCESS';
export const ADD_PREMIUM_GROUP_EVENT_FAIL = 'ADD_PREMIUM_GROUP_EVENT_FAIL';

export const GET_PREMIUM_GROUP_EVENTS_SUCCESS = 'GET_PREMIUM_GROUP_EVENTS_SUCCESS';
export const GET_PREMIUM_GROUP_EVENTS_FAIL = 'GET_PREMIUM_GROUP_EVENTS_FAIL';

export const GET_PREMIUM_ALL_EVENTS_REQUEST = 'GET_PREMIUM_ALL_EVENTS_REQUEST';
export const GET_PREMIUM_ALL_EVENTS_SUCCESS = 'GET_PREMIUM_ALL_EVENTS_SUCCESS';
export const GET_PREMIUM_ALL_EVENTS_FAIL = 'GET_PREMIUM_ALL_EVENTS_FAIL';

export const GET_PREMIUM_OTHER_EVENTS_REQUEST = 'GET_PREMIUM_ALL_EVENTS_REQUEST';
export const GET_PREMIUM_OTHER_EVENTS_SUCCESS = "GET_PREMIUM_OTHER_EVENTS_SUCCESS";
export const GET_PREMIUM_OTHER_EVENTS_FAIL = "GET_PREMIUM_OTHER_EVENTS_FAIL";

export const GET_PREMIUM_OTHER_USERS_REQUEST = 'GET_PREMIUM_ALL_USERS_REQUEST';
export const GET_PREMIUM_OTHER_USERS_SUCCESS = "GET_PREMIUM_OTHER_USERS_SUCCESS";
export const GET_PREMIUM_OTHER_USERS_FAIL = "GET_PREMIUM_OTHER_USERS_FAIL";


export const DELETE_PORTFOLIO_REQUEST = 'DELETE_PORTFOLIO_REQUEST';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_FAIL = 'DELETE_PORTFOLIO_FAIL';

export const FRIEND_STATUS_REQUEST = 'FRIEND_STATUS_REQUEST';
export const FRIEND_STATUS_PENDING = 'FRIEND_STATUS_PENDING';