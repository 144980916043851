import { 
    CONTRIBUTOR_LOGIN_SUCCESS,
    CONTRIBUTOR_LOGIN_REQUEST,
    CONTRIBUTOR_LOGIN_FAIL,
    CONTRIBUTOR_LOGOUT,
    CONTRIBUTOR_REGISTER_REQUEST,
    CONTRIBUTOR_REGISTER_SUCCESS,
    CONTRIBUTOR_REGISTER_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    SEND_RESET_PASSWORD_REQUEST,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CONTRIBUTOR_ROLES_REQUEST,
    CONTRIBUTOR_ROLES_SUCCESS,
    CONTRIBUTOR_ROLES_FAIL,
} from "constants/contributorConstants";
import {
    EMAIL_CHECK, WALLET_CHECK
} from 'constants/homeConstants';

const initialState = {
    loading: false,
    isAuthenticated: false,
    userInfo: '',
    isResetVerified: true,
    roles: [],
    isSuccess : false,
    isWalletExists: false,
}

function contributorLoginReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTOR_LOGIN_REQUEST:
            return { ...state, loading: true };
        case CONTRIBUTOR_LOGIN_SUCCESS:
            return { ...state, 
                loading: false, 
                userInfo: action.payload,
                isAuthenticated: true
            };
        case CONTRIBUTOR_LOGIN_FAIL:
            return { ...state, loading: false, error: action.payload };
        case CONTRIBUTOR_LOGOUT:
            return { ...state, userInfo: '', isAuthenticated: false };
        default: return state;
    }
}

function contributorRegisterReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTOR_REGISTER_REQUEST:
            return { ...state, loading: true };
        case CONTRIBUTOR_REGISTER_SUCCESS:
            return { ...state, loading: false };
        case CONTRIBUTOR_REGISTER_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function forgotPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true,};
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, isSuccess: true };
        case FORGOT_PASSWORD_FAIL:
            return { ...state, loading: false, isSuccess: false };
    
        default: return state;
    }
}

function sendResetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_RESET_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case SEND_RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, isResetVerified: true };
        case SEND_RESET_PASSWORD_FAIL:
            return { ...state, loading: false, isResetVerified: false };
    
        default: return state;
    }
}

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, isUpdated: true };
        case RESET_PASSWORD_FAIL:
            return { ...state, loading: false, isUpdated: false };
    
        default: return state;
    }
}

function getContributorRolesReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTOR_ROLES_REQUEST:
            return { ...state, loading: true };
        case CONTRIBUTOR_ROLES_SUCCESS:
            return { ...state, loading: false, roles: action.payload };
        case CONTRIBUTOR_ROLES_FAIL:
            return { ...state, loading: false };
    
        default: return state;
    }
}

function checkEmailExistsReducer(state = initialState, action) {
    switch (action.type) {
        case EMAIL_CHECK:
            return { ...state, isEmailExists: action.payload }
        default: return state; 
    }
}

function checkWalletAddressExistsReducer(state = initialState,action){
    switch (action.type) {
        case WALLET_CHECK:
            return {...state,isWalletExists:action.payload}
        default: return state;
    }
}

export {
    contributorLoginReducer,
    contributorRegisterReducer,
    forgotPasswordReducer,
    sendResetPasswordReducer,
    resetPasswordReducer,
    getContributorRolesReducer,
    checkEmailExistsReducer,
    checkWalletAddressExistsReducer
}