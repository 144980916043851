import React from "react";
import Cookies from 'js-cookie';

import { BsArrowReturnLeft } from "react-icons/bs";
import { useNavigate } from "react-router";

function MessageReply({ message, classProps, handleReply, handleDelete, index, members,isHidden }) {
  const navigate = useNavigate();
  const userInfo = Cookies.getJSON('userInfo') || null

  const userTag = `@${(userInfo[0]?.profiles[0]?.alias || "").replaceAll(" ", '').replace('#', '')}`

  const [context, setContext] = React.useState(false);
  const showNav = (event) => {
    event.preventDefault();
    setContext(false);
    setContext(true);
  };

  const hideContext = (event) => {
    setContext(false);
  };

  const initMenu = (chosen) => {
    if (chosen === "Reply") {
      handleReply(message);
    }
    else if (chosen === "Remove") {
      handleDelete(message);
    }
  };

  const getTimeFormat = (time) => {
    return new Date(new Date(time).toLocaleString()).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
  }
  return (
    <>
      <pre className={`${classProps} overflow-visible relative ${message?.reply?.message || message?.message ? "p-[14px]" : 'p-0'}`}
        onContextMenu={showNav}
        onClick={hideContext}
      >
        {message.reply &&
          <>
            <div className="ml-6 mt-5">
              {members?.filter(p => message?.reply.profile_id === p.profile_id)[0]?.name}
            </div>
            <div className={`flex  mx-6 py-3 border-b mb-2 reply-border-color ${message?.reply?.image ? 'justify-start' : 'justify-between'}`}>
              <div>
                <BsArrowReturnLeft className="text-3xl" />

              </div>
              <div>
                <pre className={`whitespace-pre-line border-none bg-none italic text-white m-0}`} style={{ background: 'none' }}>{message.reply.message ?    <div dangerouslySetInnerHTML={{__html: message.reply.message}}></div>  : message.reply.image ?  <a href={process.env.REACT_APP_S3_BASE_URL + message.reply.image} target="_blank" rel="noreferrer">  <img className={`m-0 max-w-[5rem] rounded-lg`} src={process.env.REACT_APP_S3_BASE_URL + message.reply.image} alt={index} /> </a> : ''}</pre>
                <div className=" opacity-50 text-sm">{getTimeFormat(message.reply.time)}</div>
              </div>
            </div>
          </>
        }
        {message?.message
          ? message?.message.includes(userTag) 
            ? <>
                {message?.message.split(" ").map((string, index) => {
                    return string === userTag 
                      ? <span className="font-bold text-[var(--primary-color3)]">{`${userTag}${index < message?.message.split(" ").length && ' '}`}</span>
                      : `${string}${index < message?.message.split(" ").length && ' '}`
                  })
                }
              </>
            :  <div dangerouslySetInnerHTML={{__html: message?.message}}></div>
          : message?.image ? <a href={process.env.REACT_APP_S3_BASE_URL + message.image} target="_blank" rel="noreferrer"> <img src={process.env.REACT_APP_S3_BASE_URL + message.image} alt={index} className="rounded-xl max-w-full h-auto" /> </a> : ''}
        {context && (
          <div
            className={`rightClick absolute right-0 w-40 ${index < 2 ? "top-full" : "bottom-full"}`}
          >
            <div className="menuElement cursor-pointer p-3 text-left text-white" onClick={() => initMenu("Reply")}>
              Reply
            </div>
            <div className={`menuElement cursor-pointer p-3 text-left text-white ${isHidden && 'hidden'}`} onClick={() => initMenu("Remove")}>
              Remove
            </div>
            <div className="menuElement cursor-pointer p-3 text-left text-white" onClick={() => initMenu("Cancel")}>
              Cancel
            </div>
          </div>
        )}
      </pre>
    </>
  );
}

export default MessageReply;