import {GET_NEWEST_PREMIUM_MEMBERS_FAILED,GET_NEWEST_PREMIUM_MEMBERS_SUCCESS, GET_NEWEST_PREMIUM_MEMBERS_REQUEST} from "../../constants/commonConstants";

const initialState = {
    loading: false,
    members: [],
}

function getNewestPremiumMembers(state = initialState, action)
{
    switch (action.type) {
        case GET_NEWEST_PREMIUM_MEMBERS_REQUEST:
            return { ...state, loading: true };
        case GET_NEWEST_PREMIUM_MEMBERS_SUCCESS:
            return { ...state, loading: false, members: action.payload };
        case GET_NEWEST_PREMIUM_MEMBERS_FAILED:
            return { ...state, loading: false };
        default: return state;
    }
}

export {
    getNewestPremiumMembers,
}