import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import Button from "components/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import InfiniteScroll from "react-infinite-scroll-component";
import { getContributors } from "actions/contributor/contributorDirectoryAction";
import { sendMessageAction, getMessageContent, addChannel, addMemberOnChannel } from "actions/common/messageActions";
import Cookies from 'js-cookie';
import { AiOutlineCheck } from "react-icons/ai";
import Checkbox from 'components/CustomCheckbox';


const AddChannelModal = ({
    handleModalDown,
    channels,
    placeholder = 'Contributors',
    channel_id,
    isCreate,
    profile_id,
    isBroadcasting,
    type = 'contributors'
}) => {
    const [selectedMembersId, setSelectedMembersId] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [loadMoreNumber, setLoadMoreNumber] = useState(6);
    const [selectAllflag, setSelectAllFlag] = useState(false);
    const [modalStatus, setModalStatus] = useState(1);
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();

    const user = Cookies.getJSON('userInfo') && Cookies.getJSON('userInfo')[0];
    const contributors = useSelector(state => state.contributors.contributors['contributors'])
    const total_count = useSelector(state => state.contributors.contributors['total_count'])
    const Loading = ({ data }) => <div>Loading</div>;
    useEffect(() => {
        let query = {
            role: -1,
            keyword: keyword === '' ? -1 : keyword,
            take: loadMoreNumber,
            exclusion: isCreate ? [profile_id] : JSON.parse(channels.filter(channel => channel.id === channel_id)[0]?.members),
            type: placeholder.toLowerCase()
        }
        dispatch(getContributors(query));
    }, [keyword, loadMoreNumber])

    // Custom Function
    const arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = arr1.sort();
        const sortedArr2 = arr2.sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }
    const isContributorSelected = (id) => {
        return selectedMembersId.includes(id)
    }

    const filterUniqueArray = (array) => {
        return array.filter((item, index) => array.indexOf(item) === index);
    }

    const handleSelectMember = (id) => {
        // Handle when user already select the contributor
        if (isContributorSelected(id)) {
            removeChannel(id);
            return;
        }

        setSelectedMembersId([...selectedMembersId, contributors.filter(contributor => contributor.id === id)[0].id]);
        setSelectedMembers([...selectedMembers, contributors.filter(contributor => contributor.id === id)[0]]);
    }

    const removeChannel = (id) => {
        setSelectedMembersId(selectedMembersId.filter(p => p !== id));
        setSelectedMembers(selectedMembers.filter(p => p.id !== id));
    }

    const handleSubmit = () => {
        if (isBroadcasting && modalStatus === 2) {
            setModalStatus(1);
            handleSend();
            handleModalDown();
            return;
        }
        if (isCreate) {
            // Check if the user has already got a same channel
            // Check DM
            if (selectedMembersId.length === 1) {
                const dmChannels = channels?.filter(channel => channel.member_cnt === 2);
                if (dmChannels.filter(dmChannel => JSON.parse(dmChannel.members).includes(selectedMembersId[0])).length > 0) {
                    const data = {
                        channel_id: dmChannels.filter(dmChannel => JSON.parse(dmChannel.members).includes(selectedMembersId[0]))[0].id,
                        type: type,
                        profile_id: profile_id
                    }
                    dispatch(getMessageContent(data));
                    isBroadcasting ? setModalStatus(2) : handleModalDown();
                    return;
                }
            }
            // Check Group
            else if (selectedMembersId.length > 1) {
                const groupChannels = channels?.filter(channel => channel.member_cnt > 2);
                if (groupChannels.filter(groupChannel => arraysAreEqual(JSON.parse(groupChannel.members), [...selectedMembersId, profile_id])).length > 0) {

                    const data = {
                        channel_id: groupChannels.filter(groupChannel => arraysAreEqual(JSON.parse(groupChannel.members), [...selectedMembersId, profile_id]))[0].id,
                        type: type,
                        profile_id: profile_id
                    }
                    dispatch(getMessageContent(data));
                    isBroadcasting ? setModalStatus(2) : handleModalDown();
                    return;
                }
            }
            else if (isBroadcasting && selectAllflag) {
                if (channels.filter(channel => channel.is_all === 1).length > 0) {
                    const data = {
                        channel_id: channels.filter(channel => channel.is_all === 1)[0].id,
                        type: type,
                        profile_id: profile_id
                    }
                    dispatch(getMessageContent(data));
                    isBroadcasting ? setModalStatus(2) : handleModalDown();
                    return;
                }
                dispatch(addChannel({ id: 'all', profile_id: profile_id, title: "Broadcasting" }))
                isBroadcasting ? setModalStatus(2) : handleModalDown();
                return;
            }
            dispatch(addChannel({ id: selectedMembersId, profile_id: profile_id, title: isBroadcasting ? "Broadcasting" : "Message Channel", type: type }));
            isBroadcasting ? setModalStatus(2) : handleModalDown();
        } else {
            // Check if the user has already got the same members in the group chat
            const groupChannels = channels?.filter(channel => channel.member_cnt > 2);
            const selfChannel = channels?.filter(channel => channel.id === channel_id)[0];
            if (groupChannels.filter(groupChannel => arraysAreEqual(JSON.parse(groupChannel.members), filterUniqueArray(selectedMembersId.concat(JSON.parse(selfChannel.members))))).length > 0) {

                const data = {
                    channel_id: groupChannels.filter(groupChannel => arraysAreEqual(JSON.parse(groupChannel.members), filterUniqueArray(selectedMembersId.concat(JSON.parse(selfChannel.members)))))[0].id,
                    type: type,
                    profile_id: profile_id
                }
                dispatch(getMessageContent(data));
                handleModalDown();
                return;
            }
            // Dispatch Add member action
            let requests = {
                channel_id: channel_id,
                member_id: selectedMembersId
            }
            dispatch(addMemberOnChannel(requests)).then(
                handleModalDown()
            )
        }
    }

    const handleScroll = () => {
        if (total_count <= loadMoreNumber) {
            setHasMore(false);
            return;
        }
        setTimeout(() => {
            setLoadMoreNumber(loadMoreNumber + 3);
        },
            500)
    }

    const handleSend = () => {
        if (message === '' || message === '\n') return;
        const data = {
            channel_id: channel_id,
            profile_id: profile_id,
            message: message.trim(),
            type: "contributors",
            time: new Date().toUTCString(),
            reply: null
        };
        dispatch(sendMessageAction(data));
        setMessage('');
    }

    const handleMessage = (e) => {
        setMessage(e.target.value);
    };

    return (
        <div>
            <div className="flex justify-between">
                <div className="text-white -mt-5 flex items-center">
                    <RxCross1 className="text-white cursor-pointer" onClick={handleModalDown} />
                    <div className="contributor-message-setting-modal-title ml-4">
                        {isBroadcasting ?
                            <>Broadcast Message</>
                            :
                            <>New Message</>
                        }
                    </div>
                </div>
                <Button
                    withIcon={false}
                    width={135}
                    height={35}
                    fontSize={1.1}
                    text={(modalStatus === 1 && isBroadcasting) || !isBroadcasting ? 'Next' : 'Send'}
                    className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button`}
                    lineHeight={0}
                    onClick={handleSubmit}
                />
            </div>
            {(modalStatus === 1 && isBroadcasting) || !isBroadcasting ?
                <div className="search-bar-wrapper text-white">
                    <div className="w-full relative search-bar">
                        <FiSearch className='absolute' />
                        <input
                            type="text"
                            placeholder={`Search ${placeholder}`}
                            style={{ fontStyle: keyword === '' ? 'italic' : 'normal' }}
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </div>
                    {isBroadcasting &&
                        <div>
                            <Checkbox label="All Contributors" checked={selectAllflag} onChange={() => setSelectAllFlag(!selectAllflag)} />
                        </div>
                    }
                    <div className="flex mt-1 gap-x-3">
                        {((!selectAllflag && isBroadcasting) || !isBroadcasting) && selectedMembers.map((p, index) =>
                            <div key={index} className="selected-channel p-1 flex items-center">
                                <img
                                    className="inline-block object-cover"
                                    src={
                                        process.env.REACT_APP_S3_BASE_URL + p.avatar
                                    }
                                    alt={
                                        p.alias ? p.alias : p.user.name
                                    }
                                />
                                <div className="contributor-message-room-name pl-1">
                                    {
                                        p.alias ? p.alias : p.user.name
                                    }
                                </div>
                                <RxCrossCircled className="pl-1 text- text-4xl cursor-pointer" onClick={() => removeChannel(p)} />
                            </div>
                        )}
                    </div>
                    <div id="scrollableDiv" className="row channel-container mt-2">
                        <InfiniteScroll
                            dataLength={loadMoreNumber}
                            next={handleScroll}
                            hasMore={hasMore}
                            loader={<h4>Loading...</h4>}
                            endMessage={<></>}
                            height={400}
                        >
                            {contributors?.filter(p => p.id !== user?.id).map((p, index) =>
                                <div onClick={() => handleSelectMember(p.id)} className="contributor-message-room-container cursor-pointer flex items-center" key={index} >
                                    <img
                                        className="inline-block object-cover"
                                        src={process.env.REACT_APP_S3_BASE_URL + (p.avatar ? p.avatar : 'user.png')}
                                        alt={p?.alias}
                                    />
                                    <div className="contributor-message-users-title pr-10 flex justify-between">
                                        <div className="contributor-message-room-name">
                                            {p?.alias ? p.alias : p.user.name}
                                        </div>
                                        <div className="contributor-message-room-checkmark">
                                            {
                                                isContributorSelected(p.id) ?
                                                    <AiOutlineCheck className="text-4xl" />
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
                :
                <div className="contributor-message-room-textarea mt-10 rounded-3xl">
                    <div className="relative">
                        <ReactTextareaAutocomplete
                            className="message-textarea"
                            placeholder="Write the broadcast Message"
                            loadingComponent={Loading}
                            style={{
                                fontSize: "18px",
                                lineHeight: "20px",
                                padding: "5px",
                                height: "30px",
                                resize: "none",
                                fontStyle: message !== '' ? 'normal' : 'italic',
                                height: "400px",
                                color: "white"
                            }}
                            containerStyle={{
                                padding: "10px 20px"
                            }}
                            dropdownStyle={{
                                top: "unset!important",
                                bottom: "100%"
                            }}
                            onChange={handleMessage}
                            value={message}
                            minChar={0}
                            trigger={{}}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default AddChannelModal