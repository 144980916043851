import { 
    GET_PREMIUM_PROFILE_REQUEST,
    GET_PREMIUM_PROFILE_SUCCESS,
    GET_PREMIUM_PROFILE_FAIL,
} from "constants/premiumConstants";

const initialState = {
    loading: false,
    whitepapers: [],
    addProfileId: null,
    removeProfileId: null,
}

function getPremiumProfileReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PREMIUM_PROFILE_REQUEST:
            return { ...state, loading: true };
        case GET_PREMIUM_PROFILE_SUCCESS:
            return { ...state, loading: false, premiumProfile: action.payload};
        case GET_PREMIUM_PROFILE_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

export {
    getPremiumProfileReducer,
}