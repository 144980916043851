import { 
    CREATE_EVENT_REQUEST,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_FAIL,
    GET_EVENTS_CATEGORIES,
    GET_EVENTS_BY_MONTH_REQUEST,
    GET_EVENTS_BY_MONTH_SUCCESS,
    GET_EVENTS_BY_MONTH_FAIL,
    EVENT_BY_ID_REQUEST,
    EVENT_BY_ID_SUCCESS,
    EVENT_BY_ID_FAIL,
    EVENT_NEAREST_REQUEST,
    EVENT_NEAREST_SUCCESS,
    EVENT_NEAREST_FAIL,
} from "constants/contributorConstants";

const initialState = {
    loading: false,
    categories: [],
    events: [],
    eventById: [],
    newEvent: {}
}

function createEventReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_EVENT_REQUEST:
            return { ...state, loading: true };
        case CREATE_EVENT_SUCCESS:
            return { ...state, loading: false, newEvent: action.payload};
        case CREATE_EVENT_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function getEventsCategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EVENTS_CATEGORIES:
            return { ...state, categories: action.payload }
        default: return state;
    }
}

function getEventsByMonthReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EVENTS_BY_MONTH_REQUEST:
            return { ...state, loading: true };
        case GET_EVENTS_BY_MONTH_SUCCESS:
            return { ...state, loading: false, events: action.payload };
        case GET_EVENTS_BY_MONTH_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function eventByIdReducer( state = initialState, action) {
    switch (action.type) {
        case EVENT_BY_ID_REQUEST:
            return { ...state, loading: true };
        case EVENT_BY_ID_SUCCESS:
            return { ...state, loading: false, eventById: action.payload };
        case EVENT_BY_ID_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}


function eventNearestReducer( state = initialState, action) {
    switch (action.type) {
        case EVENT_NEAREST_REQUEST:
            return { ...state, loading: true };
        case EVENT_NEAREST_SUCCESS:
            return { ...state, loading: false, nearestEvents: action.payload.events };
        case EVENT_NEAREST_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

export {
    createEventReducer,
    getEventsCategoriesReducer,
    getEventsByMonthReducer,
    eventByIdReducer,
    eventNearestReducer
}