import {
    CREATE_BLOGS_REQUEST,
    CREATE_BLOGS_SUCCESS,
    CREATE_BLOGS_FAIL,
    BLOGS_REQUEST,
    BLOGS_SUCCESS,
    BLOGS_FAIL,
    BLOGS_BY_ID_REQUEST,
    BLOGS_BY_ID_SUCCESS,
    BLOGS_BY_ID_FAIL,
    BLOG_COMMENTS_REQUEST,
    BLOG_COMMENTS_SUCCESS,
    BLOG_COMMENTS_FAIL,
    BLOG_LIKES_REQUEST,
    BLOG_LIKES_SUCCESS,
    BLOG_LIKES_FAIL,
    BLOG_COMMENT_REQUEST,
    BLOG_COMMENT_SUCCESS,
    BLOG_COMMENT_FAIL,
} from "constants/contributorConstants";

const initialState = {
    loading: false,
    loadingComments: false,
    categories: [],
    blogs: [],
    blogsById: [],
    blogCommentsById: [],
}

function createBlogsReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_BLOGS_REQUEST:
            return { ...state, loading: true };
        case CREATE_BLOGS_SUCCESS:
            return { ...state, loading: false, status: true };
        case CREATE_BLOGS_FAIL:
            return { ...state, loading: false, status: false };
        default: return state;
    }
}

function blogsReducer(state = initialState, action) {
    switch (action.type) {
        case BLOGS_REQUEST:
            return { ...state, loading: true };
        case BLOGS_SUCCESS:
            return { ...state, loading: false, blogs: action.payload };
        case BLOGS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function blogsByIdReducer(state = initialState, action) {
    switch (action.type) {
        case BLOGS_BY_ID_REQUEST:
            return { ...state, loading: true };
        case BLOGS_BY_ID_SUCCESS:
            return { ...state, loading: false, blogsById: action.payload };
        case BLOGS_BY_ID_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function blogCommentsByIdReducer(state = initialState, action) {
    switch (action.type) {
        case BLOG_COMMENTS_REQUEST:
            return { ...state, loadingComments: true };
        case BLOG_COMMENTS_SUCCESS:
            return { ...state, loadingComments: false, blogCommentsById: action.payload };
        case BLOG_COMMENTS_FAIL:
            return { ...state, loadingComments: false };
        default: return state;
    }
}

function updateBlogLikesReducer(state = initialState, action) {
    switch (action.type) {
        case BLOG_LIKES_REQUEST:
            return { ...state, loading: true };
        case BLOG_LIKES_SUCCESS:
            return { ...state, loading: false, blogLikes: action.payload };
        case BLOG_LIKES_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function createBlogCommentReducer(state = initialState, action) {
    switch (action.type) {
        case BLOG_COMMENT_REQUEST:
            return { ...state, loading: true };
        case BLOG_COMMENT_SUCCESS:
            return { ...state, loading: false, comments: action.payload };
        case BLOG_COMMENT_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}


export {
    createBlogsReducer,
    blogsReducer,
    blogsByIdReducer,
    updateBlogLikesReducer,
    createBlogCommentReducer,
    blogCommentsByIdReducer
}