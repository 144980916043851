import { useDropzone } from "react-dropzone";
import { MdFileUpload } from 'react-icons/md';
import { AiFillDelete } from "react-icons/ai";
import { useState } from "react";
import { checkBase64 } from "utils/checkBase64";

function Dropzone({ onDrop, image, handleDelete, theme, width = 367, height = 367 }) {
    const {
        getInputProps,
        acceptedFiles,
        open,
    } = useDropzone({
        accept: 'image/*',
        onDrop,
        noClick: true,
        noKeyboard: true,
    });
    const lists = acceptedFiles.map((list) => {
        <li key={list.path}>
            { list.path } - { list.size } bytes
        </li>
    });

    const handleDeleteClick = (id) => {
        handleDelete(id)
    }

    return (
        <>
            {' '}
            <section className="dropbox mb-3">
                {
                    image ?
                    <div 
                        className={`flex items-center justify-center cursor-pointer`}
                        style={theme == 'full' ? {width: "100%", height: "300px"} : {width: `${width}px`, height: `${height}px`}}
                    >
                        <div className="contributor-create-profile-image-card-wrapper relative flex items-center justify-center overflow-hidden rounded-xl">
                            <img src={checkBase64(image) ? image : process.env.REACT_APP_S3_BASE_URL + image} className="object-cover" style={theme == 'full' ? {maxWidth: "400px", maxHeight: "300px"} : {width: `${width}px`, height: `${height}px`}}/>
                            <AiFillDelete className="image-delete-wrapper cursor-pointer absolute rounded-full left-1/2 -translate-x-1/2 top-2/3 image-delete-wrapper w-10 h-10" onClick={() => handleDeleteClick()}/>
                        </div>
                    </div>
                    :
                    <div
                        className={`dropbox-wrapper flex items-center justify-center cursor-pointer ${theme == 'full' && 'border-dashed'}`}
                        style={theme == 'full' ? {width: "100%", height: `${height}px`} : {width: `${width}px`, height: `${height}px`}}
                        onClick={open}
                    >
                        <input {...getInputProps()} />
                        <MdFileUpload />
                    </div>
                }
            </section>
        </>
    )
}

export default Dropzone;