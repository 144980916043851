import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from 'store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './ScrollToTop';
import './index.css';
import './fonts/Poppins/Poppins-Bold.ttf';
import './fonts/Poppins/Poppins-Regular.ttf';
import TerraStationMobileWallet from '@terra-money/terra-station-mobile';
import { WalletProvider, getInitialConfig } from '@terra-money/wallet-kit';

window.Buffer = require('buffer/').Buffer;

getInitialConfig().then((defaultNetworks) => {
  ReactDOM.render(
      <WalletProvider extraWallets={[new TerraStationMobileWallet()]} defaultNetworks={defaultNetworks}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </WalletProvider>,
    document.getElementById('root')
  );
}).catch(err => {
  console.log('Load error', err);
  alert('Error try again.');
});

