import { 
    CONTRIBUTOR_ROLES
} from "constants/homeConstants";
import { UPLOAD_FILE } from "constants/premiumConstants";

const initialState = {
    loading: false,
    isAuthenticated: false,
    contributorRoles: [],
    premiumRoles: [],
}

function contributorRolesReducer(state = initialState, action) {
    switch (action.type) {
        case CONTRIBUTOR_ROLES:
            return { ...state, contributorRoles: action.payload };
        default: return state;
    }
}
export {
    contributorRolesReducer,
}