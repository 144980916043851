import requests from '../httpService';
import Cookies from 'js-cookie';
import { notifyError, notifySuccess } from 'utils/toast';
import {
    CONTRIBUTOR_LOGIN_REQUEST,
    CONTRIBUTOR_LOGIN_SUCCESS,
    CONTRIBUTOR_LOGIN_FAIL,
    CONTRIBUTOR_LOGOUT,
    CONTRIBUTOR_REGISTER_REQUEST,
    CONTRIBUTOR_REGISTER_SUCCESS,
    CONTRIBUTOR_REGISTER_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    SEND_RESET_PASSWORD_REQUEST,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CONTRIBUTOR_ROLES_REQUEST,
    CONTRIBUTOR_ROLES_SUCCESS,
    CONTRIBUTOR_ROLES_FAIL,
} from 'constants/contributorConstants';
import {
    REMOVE_USER_INFO,
    EMAIL_CHECK, WALLET_CHECK
} from 'constants/homeConstants';

const checkEmailExists = (body) => async (dispatch) => {
    await requests.put('/email-check', body)
        .then(res => {
            if (res) {
                dispatch({ type: EMAIL_CHECK, payload: res.state })
            }
        })
        .catch(err => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
        })
}

const checkWalletAddressExists = (body) => async (dispatch) => {
    await requests.put('/wallet-check',body)
        .then(res => {
            if(res) {
                dispatch({ type: WALLET_CHECK, payload: res.state })
            }
        })
        .catch( err => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
        })
}

const contributorLogin = (body) => async (dispatch) => {
    dispatch({ type: CONTRIBUTOR_LOGIN_REQUEST, payload: { body } })
    await requests.post('/contributors/login', body)
        .then((res) => {
            if (res) {
                dispatch({ type: CONTRIBUTOR_LOGIN_SUCCESS, payload: res })
                Cookies.set('userInfo', JSON.stringify(res));
                Cookies.set('luncsphere_profile', res[0].profiles[0].id);
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: CONTRIBUTOR_LOGIN_FAIL, payload: err })
        })
}

const contributorLogout = () => async (dispatch) => {
    await requests.post('/contributors/logout')
        .then((res) => {
            if (res) {
                Cookies.remove('userInfo');
                Cookies.remove('luncsphere_profile');
                dispatch({ type: CONTRIBUTOR_LOGOUT })
                dispatch({ type: REMOVE_USER_INFO })
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
        })
}

const contributorRegister = (body) => async (dispatch) => {
    dispatch({ type: CONTRIBUTOR_REGISTER_REQUEST, payload: { body } })
    await requests.post('/contributors/register', body)
        .then((res) => {
            if (res) {
                notifySuccess('Register Request sent!')
                dispatch({ type: CONTRIBUTOR_REGISTER_SUCCESS, payload: res })
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: CONTRIBUTOR_REGISTER_FAIL, payload: err })
        })
}

const forgotPassword = ({type , body}) => async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST, payload: { body } })
    await requests.post(`/${type}/forget-password`, body)
        .then((res) => {
            if (res) {
                notifySuccess('We\'ve sent email!')
                dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: res })
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: FORGOT_PASSWORD_FAIL, payload: err })
        })
}

const sendResetPasswordRequest = (token) => async (dispatch) => {
    dispatch({ type: SEND_RESET_PASSWORD_REQUEST, payload: { token } })
    await requests.get(`/contributors/reset-password/${token}`)
        .then((res) => {
            if (res) {
                dispatch({ type: SEND_RESET_PASSWORD_SUCCESS, payload: res })
                notifySuccess(res.message)
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: SEND_RESET_PASSWORD_FAIL, payload: err })
        })
}

const resetPassword = (body) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST, payload: { body } })
    await requests.post(`/${body.type}/reset-password`, body)
        .then((res) => {
            if (res) {
                notifySuccess(res.message)
                dispatch({ type: RESET_PASSWORD_SUCCESS, payload: res })
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: RESET_PASSWORD_FAIL, payload: err })
        })
}

const getContributorRoles = (body) => async (dispatch) => {
    dispatch({ type: CONTRIBUTOR_ROLES_REQUEST, payload: { body } })
    await requests.get('/get-roles')
        .then((res) => {
            if (res) {
                dispatch({ type: CONTRIBUTOR_ROLES_SUCCESS, payload: res })
            }
        })
        .catch((err) => {
            notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
            dispatch({ type: CONTRIBUTOR_ROLES_FAIL, payload: err })
        })
}

export {
    contributorLogin,
    contributorLogout,
    contributorRegister,
    forgotPassword,
    sendResetPasswordRequest,
    resetPassword,
    getContributorRoles,
    checkEmailExists,
    checkWalletAddressExists
}