import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ContributorProfileContext } from 'context/contributorProfileContext';

const ContributorProfilePrivateRoute = ({ children, ...rest }) => {
    const { isProfileComplete } = useContext(ContributorProfileContext);
    return (
        isProfileComplete ? (
                children
                ) : (
                    <Navigate to="/contributor/create-profile" replace />
                )
    );
};

export default ContributorProfilePrivateRoute;
