const Checkbox = ({ label, checked = false, onClick, ...props }) => {
    return (
        <div>
            <label className="checkbox-wrapper">
                {label}
                <input type="checkbox" checked={checked} onChange={onClick} {...props}/>
                <span className="checkmark"></span>
            </label>
        </div>
    )
}
export default Checkbox