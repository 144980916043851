import './App.css';
import React, { Suspense,useEffect } from 'react';
import { 
    Routes,
    Route,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import { AuthProvider } from "./context/authContext";
import { ThemeProvider } from './context/themeContext';
import { LanguageProvider } from './context/languageContext';
import { ContributorProfileProvider } from 'context/contributorProfileContext';
import history from '@history';
import Spinner from 'components/layouts/Spinner';
import routes from './pages/index';
import Cookies from 'js-cookie';
import Echo from 'laravel-echo';

function App() {
    useEffect(() => {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: true,
            forceTLS: true
          });
    },[])
    
    return (
        <AuthProvider>
            <ContributorProfileProvider>
                <ThemeProvider>
                    <LanguageProvider>
                        <Suspense fallback={<Spinner/>}>
                                <Routes history={history}>
                                    {
                                        routes.map((data,index) => (
                                            <Route onUpdate={() => {
                                                window.scrollTo(0, 0)
                                                Cookies.set('group_id', 0)
                                            }} exact={true} path={data.path} element={data.component} key={index} />
                                            ))
                                    }
                                </Routes>
                            <ToastContainer />
                        </Suspense>
                    </LanguageProvider>
                </ThemeProvider>
            </ContributorProfileProvider>
        </AuthProvider>        
    );
}

export default App;
