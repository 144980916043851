import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/authContext';
import { notifyInform } from 'utils/toast';

const PrivateRoute = ({ children, userType='contributor', ...rest }) => {
  const { authState } = useContext(AuthContext);
  const { isAuthenticated, isContributor, isPremium} = authState;

  
  !isAuthenticated && notifyInform('Please login first')

  return (
    isAuthenticated ? (
            children
            ) : (
              <>
                {
                  userType === 'contributor' ?
                  <Navigate to="/contributor-login" replace />
                  :
                  <Navigate to="/premium-login" replace />
                }
              </>
            )
  );
};

export default PrivateRoute;
