import requests from '../httpService';
import { notifyError, notifySuccess } from 'utils/toast';
import {
  GET_PREMIUM_PROFILE_REQUEST,
  GET_PREMIUM_PROFILE_SUCCESS,
  GET_PREMIUM_PROFILE_FAIL,
  SUBSCRIPTION_PURCHASE_REQUEST,
  SUBSCRIPTION_PURCHASE_FAIL,
  SUBSCRIPTION_PURCHASE_SUCCESS, DELETE_PORTFOLIO_SUCCESS, DELETE_PORTFOLIO_REQUEST, DELETE_PORTFOLIO_FAIL,
} from 'constants/premiumConstants';
import Cookies from 'js-cookie';

const profileId = Cookies.getJSON('luncsphere_profile') || null;

const getProfileById = (body,type) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_PROFILE_REQUEST, payload: { body } })
  await requests.get(`/${ type ? type : 'premium'}/${body}/get-profile-by-id`)
    .then((res) => {
      if (res) {
        dispatch({ type: GET_PREMIUM_PROFILE_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_PROFILE_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const addPortfolioById = (body, id) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_PROFILE_REQUEST, payload: { body } })
  await requests.put(`/premium/add-portfolio`, body)
    .then((res) => {
      if (res) {
        notifySuccess("Successfull!")
        dispatch(getProfileById(id));
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_PROFILE_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const deletePortfolioById = ({type = 'premium',portfolio_id}) => async (dispatch) => {
  dispatch({ type: DELETE_PORTFOLIO_REQUEST, payload: {isLoading: true, type, portfolio_id } })
  await requests.delete(`/${type}/delete-portfolio/${portfolio_id}`)
    .then((res) => {
      if (res) {
        notifySuccess("Successfull!")
        dispatch({ type: DELETE_PORTFOLIO_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      dispatch({ type: DELETE_PORTFOLIO_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const editProfile = (body) => async (dispatch) => {
  dispatch({ type: GET_PREMIUM_PROFILE_REQUEST, payload: { body } })
  await requests.post(`/premium/update-profile`, body)
    .then((res) => {
      if (res) {
        notifySuccess("Successfull!")
        dispatch(getProfileById(body.profile_id));
      }
    })
    .catch((err) => {
      dispatch({ type: GET_PREMIUM_PROFILE_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

const subscriptionPurchase = (body) => async (dispatch) => {
  dispatch({ type: SUBSCRIPTION_PURCHASE_REQUEST, payload: body })
  await requests.post(`/premium/subscription/purchase`, body)
    .then((res) => {
      if (res) {
        dispatch({ type: SUBSCRIPTION_PURCHASE_SUCCESS, payload: res })
      }
    }).catch((err) => {
      dispatch({ type: SUBSCRIPTION_PURCHASE_FAIL, payload: err })
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
    })
}

export {
  getProfileById,
  addPortfolioById,
  editProfile,
  subscriptionPurchase,
  deletePortfolioById
}