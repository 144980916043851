import { 
    CREATE_NEWS_REQUEST,
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_FAIL,
    GET_NEWS_CATEGORIES,
    GET_NEWS_CATEGORIES_REQUEST,
    NEWS_REQUEST,
    NEWS_SUCCESS,
    NEWS_FAIL,
    NEWS_BY_ID_REQUEST,
    NEWS_BY_ID_SUCCESS,
    NEWS_BY_ID_FAIL,
    NEWS_CATEGORY_ID,
} from "constants/contributorConstants";

const initialState = {
    loading: false,
    categories: [],
    news: [],
    newsById: [],
    newNews: {}
}

function createNewsReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_NEWS_REQUEST:
            return { ...state, loading: true };
        case CREATE_NEWS_SUCCESS:
            return { ...state, loading: false, newNews: action.payload};
        case CREATE_NEWS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function newsCategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NEWS_CATEGORIES_REQUEST:
            return { ...state, loading: true };
        case GET_NEWS_CATEGORIES:
            return { ...state, categories: action.payload, loading: false };
        case NEWS_CATEGORY_ID: 
            return { ...state, categoryId: action.payload };
        default: return state;
    }
}

function newsReducer(state = initialState, action) {
    switch (action.type) {
        case NEWS_REQUEST:
            return { ...state, loading: true };
        case NEWS_SUCCESS:
            return { ...state, loading: false, news: action.payload };
        case NEWS_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

function newsByIdReducer( state = initialState, action) {
    switch (action.type) {
        case NEWS_BY_ID_REQUEST:
            return { ...state, loading: true };
        case NEWS_BY_ID_SUCCESS:
            return { ...state, loading: false, newsById: action.payload };
        case NEWS_BY_ID_FAIL:
            return { ...state, loading: false };
        default: return state;
    }
}

export {
    createNewsReducer,
    newsCategoriesReducer,
    newsReducer,
    newsByIdReducer
}