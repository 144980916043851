import React, { useEffect, useState, useCallback } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Button from "components/buttons/Button";
import { RxCross1 } from "react-icons/rx";
import { notifyError } from "utils/toast";
import { useDispatch } from "react-redux";
import { changeGroupInfo } from "actions/common/messageActions";
import Dropzone from "components/Dropzone";
import { checkBase64 } from 'utils/checkBase64';

const MessageSettingModal = ({
    handleModalDown,
    id,
    title,
    avatar
}) => {
    const [ status, setStatus ] = useState(0);
    const [ setting, setSetting ] = useState({
      newTitle: '',
      newAvatar: ''
    });
    const dispatch = useDispatch();
    useEffect(() => {
      setSetting({newTitle: title, newAvatar: avatar});
    }, []);
    const handleSubmit = () => {
      if(status === 1) {
        if(setting.newTitle === '') {
          notifyError('Group name is required!');
          return;
        }
      }
      if(status === 2) {
        if(setting.newTitle === '') {
          notifyError('Group avatar is required!');
          return;
        }
      }
      console.log(setting.newTitle, title)
      if (setting.newTitle === title && setting.newAvatar === avatar) {
        handleModalDown();
        return;
      }
      let requests = {
        channel_id: id,
        title: setting.newTitle,
        image: checkBase64(setting.newAvatar) ? setting.newAvatar : null
      }
      dispatch(changeGroupInfo(requests))
      handleModalDown();
    }
    const onDropImage = useCallback((acceptedFiles) => {
      acceptedFiles.map((file) => {
          let reader = new FileReader();
          reader.onload = function(e) {
              // setImage(e.target.result)
              setSetting({...setting, newAvatar: e.target.result});
          }
          reader.readAsDataURL(file)
          return file;
      })
    })

    const handleDeleteImage = () => {
        setSetting({...setting, newAvatar: null})
    }
    const modalTitle = ['Need to change something?', 'Change Group Name', 'Change Group Photo'];
    const Setting = [
      <>
        <Button 
            withIcon={false}
            height={35}
            fontSize={1.1}
            text={'Change group name'}
            className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button w-50 mt-20`}
            lineHeight={0}
            theme={"transparent"}
            onClick={() => setStatus(1)}
        />
        <Button 
            withIcon={false}
            height={35}
            fontSize={1.1}
            text={'Change group photo'}
            className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button w-50 mt-10`}
            lineHeight={0}
            theme={"transparent"}
            onClick={() => setStatus(2)}
        />
      </>,
      <div className="w-full">
        <div className="text-lg font-medium">
          Group Name
        </div>
        <input 
            type="text" 
            value={setting.newTitle}
            className="rounded-2xl border-2 border-cyan-50 bg-black"
            onChange={(e) => setSetting({...setting, newTitle: e.target.value})}
        />
      </div>,
      <>
        <Dropzone 
          onDrop={onDropImage} 
          image={setting.newAvatar} 
          handleDelete={handleDeleteImage}
          theme={'full'}
          height={250}
        />
      </>
    ]
    return (
        <div>
            <RxCross1 className="text-white -mt-3 cursor-pointer" onClick={handleModalDown} />
            <div className="text-white flex flex-col items-center contributor-message-setting-modal-header">
                <div className="contributor-message-setting-modal-title mb-10">{ modalTitle[status] }</div>
                {Setting[status]}
            </div>
            {status !== 0 &&
            <div className="custom-modal-footer flex justify-end">
                <Button 
                    withIcon={true}
                    icon={<AiOutlineCheckCircle/>}
                    width={135}
                    height={35}
                    fontSize={1.1}
                    text={'Done'}
                    className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button`}
                    lineHeight={0}
                    theme={"transparent"}
                    onClick={handleSubmit}
                />
            </div>
            }
        </div>
    )
}

export default MessageSettingModal