import Dropzone from "components/Dropzone";
import React, { useState, useEffect } from "react";
import { useLanguageContext } from "context/languageContext";
import { GiCancel } from "react-icons/gi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Button from "components/buttons/Button";
import enUS from 'data/en-US'
import { notifyError } from "utils/toast";

const MessageUploadModal = ({ onDropImage, image, handleDeleteImage, handleModalDown, handleSendMessage }) => {
  // Pull the page content data from JSON
  const { language } = useLanguageContext();
  const [data, setData] = useState();
  const info = {
    'en-US': enUS
  }

  useEffect(() => {
    setData(info[language].premium_message.editor);

  }, []);

  const handleCancel = () => {
    handleModalDown()
  }

  return (<div className="event-upload-modal-content font-face-poppins">
    <div className="custom-modal-title">
      {data?.image.title}
    </div>
    <div className="custom-modal-body mt-4">
      <div className="row">
        <div className="col-md-5 mx-auto">
          <Dropzone
            onDrop={onDropImage}
            image={image}
            handleDelete={handleDeleteImage}
            theme={'full'}
            height={250}
          />
        </div>
      </div>
    </div>
    <div className="custom-modal-footer flex justify-end">
      <Button
        withIcon={true}
        icon={<GiCancel />}
        fontSize={1.1}
        width={135}
        height={35}
        text={'Cancel'}
        className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button`}
        lineHeight={0}
        theme={"transparent"}
        onClick={handleCancel}
      />
      <Button
        withIcon={true}
        icon={<AiOutlineCheckCircle />}
        width={135}
        height={35}
        fontSize={1.1}
        text={data?.image?.send}
        className={`flex items-center justify-evenly capitalize ml-3 bg-transparent modal-button`}
        lineHeight={0}
        theme={"transparent"}
        onClick={handleSendMessage}
      />
    </div>
  </div>)
}

export default MessageUploadModal