import {
    REGISTER_PREMIUM,
    UPLOAD_FILE,
    PREMIUM_LOGIN_SUCCESS,
    PREMIUM_LOGIN_FAIL,
    PREMIUM_LOGIN_REQUEST,
    PREMIUM_LOGOUT
} from "constants/premiumConstants";

const initialState = {
    loading: false,
    uploadedUrl: '',
    isAuthenticated: false,
    userInfo: '',
    isResetVerified: true,
    roles: [],
    isSuccess: false,
}

function premiumRegisterReducer(state = initialState, action) {
    switch (action.type) {
        case REGISTER_PREMIUM:
            return { ...state, freelancerServices: action.payload };
        case UPLOAD_FILE:
            return { ...state, uploadedUrl: action.payload };
        default: return state;

    }
}

function premiumLoginReducer(state = initialState, action) {
    switch (action.type) {
        case PREMIUM_LOGIN_REQUEST:
            return { ...state, loading: true };
        case PREMIUM_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: action.payload,
                isAuthenticated: true,
            };
        case PREMIUM_LOGIN_FAIL:
            return { ...state, loading: false, error: action.payload, isAuthenticated: false };
        case PREMIUM_LOGOUT:
            return { ...state, userInfo: '', isAuthenticated: false };
        default: return state;
    }
}

export {
    premiumRegisterReducer,
    premiumLoginReducer,

}