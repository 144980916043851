const initialState = {
    isMapScriptLoaded: false,
    apiKey: null,
};

const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MAP_SCRIPT_LOADED':
            return { ...state, isMapScriptLoaded: true };
        case 'SET_MAP_API_KEY':
            return { ...state, apiKey: action.payload };
        default:
            return state;
    }
};

export default mapReducer;