import { ADD_FRIEND_SUCCESS, CHECK_FRIENDSHIP_STATUS, GET_INCOMING_FRIEND_REQUESTS_SUCCESS, UPDATE_FRIENDSHIP_STATUS_SUCCESS } from "constants/commonConstants";
import {
    ADD_CHANNEL,
    GET_CHANNELS,
    GET_CHANNELS_FAILED,
    CHANNEL_ID,
    CHANGE_CHANNEL_INFO,
    GET_MESSAGE_CONTENT,
    GET_MESSAGE_VIA_PUSHER,
    LEAVE_CHANNEL,
    REMOVE_MESSAGE,
    GET_MORE_MESSAGE_CONTENT,
    RECEIVE_CHANNEL,
    GO_TO_DM_CHANNEL,
    CHANGE_INFO_VIA_PUSHER,
    SET_CHANNEL_ID,
    DISCUSSION_SET_ID,
    GET_UNREAD_MESSAGE,
    SET_MESSAGE_REQUEST
} from "constants/contributorConstants";
import { JOIN_CHANNEL, JOIN_GROUP } from "constants/premiumConstants";
import Cookies from 'js-cookie';

const initialState = {
    totalChannel: [],
    channels: [],
    channels_flag: -1,
    loading: false,
    profile: [],
    featuredContributor: [],
    messageContent: [],
    channel_id: 0,
    selectedChannel: {},
    totalUnread: 0,
    previousUrl: '',
    loadMore: false,
    newMessageFlag: false,
    incomingFriendRequests: null,
    addFriendStatus: null,
    updateFriendStatus: null,
}

function messageReducer(state = initialState, action) {
    const profile_id = Cookies.getJSON('luncsphere_profile') || null;
    let channels;

    switch (action.type) {
        case SET_MESSAGE_REQUEST:
            return { ...state, loading: true };
        case GET_CHANNELS:
            channels = action.payload.length > 0 && action.payload[0];
            return {
                ...state,
                channels: channels,
                channel_id: Cookies.getJSON('channel_id') || 0,
                selectedChannel: channels.filter(p => p.id === (Cookies.getJSON('channel_id') && Cookies.getJSON('channel_id')))[0],
                channels_flag: (state.channels_flag + 1) % 2,
                loading: false,
                totalUnread: channels.filter(p => p?.notifications?.length > 0).map(p => p?.notifications?.filter(p => p.profile_id === profile_id)[0]?.count || 0).reduce((accumulator, currentValue) => accumulator + (currentValue ? currentValue : 0), 0)
            };
        case GET_CHANNELS_FAILED:
            return { ...state, loading: false };
        case ADD_CHANNEL:
            Cookies.set('channel_id', action.payload.channel_id);
            return { ...state, channel_id: action.payload.channel_id, after_channel_id: action.payload.channel_id, loading: false }
        case GO_TO_DM_CHANNEL:
            Cookies.set('channel_id', action.payload);
            return {
                ...state,
                channel_id: action.payload
            }
        case SET_CHANNEL_ID:
            return { ...state, channel_id: action.payload, loading: false }
        case RECEIVE_CHANNEL:
            return { ...state, receive_channel_id: action.payload, loading: false }
        case CHANNEL_ID:
            return { ...state, channel_id: CHANNEL_ID, loading: false }
        case CHANGE_CHANNEL_INFO:
            return {
                ...state,
                channels: state.channels.map(channel => channel.id === action.payload.id ? action.payload : channel),
                selectedChannel: action.payload,
                loading: false
            }
        case CHANGE_INFO_VIA_PUSHER:
            channels = state.channels.map(p => action.payload.id === p.id ? action.payload : p);
            return { ...state, channels: channels, selectedChannel: action.payload, loading: false }
        case GET_MESSAGE_CONTENT:
            channels = state.channels.map(p => action.payload.channel_id === p.id ? {
                ...p, notifications: []
            } : p)
            Cookies.set('channel_id', action.payload.channel_id);
            return {
                ...state,
                messageContent: action.payload.messageContent.data ? action.payload.messageContent.data : [],
                selectedChannel: channels.filter(p => p.id === action.payload.channel_id)[0],
                previousUrl: action.payload.messageContent.previousUrl ? action.payload.messageContent.previousUrl : "",
                channels: channels,
                loadMore: false,
                channel_id: action.payload.channel_id,
                newMessageFlag: false,
                loading: false,
                totalUnread: channels.filter(p => p?.notifications?.length > 0).map(p => p.notifications.filter(p => p.profile_id === profile_id)[0]?.count).reduce((accumulator, currentValue) => accumulator + (currentValue ? currentValue : 0), 0)
            }
        case GET_MORE_MESSAGE_CONTENT:
            return {
                ...state,
                previousUrl: action.payload.previousUrl,
                messageContent: [...action.payload?.data, ...state.messageContent],
                loadMore: true,
                newMessageFlag: false,
                loading: false
            }
        case GET_UNREAD_MESSAGE:
            channels = state.channels.map(p => action.payload.channel_id === p.id ? { ...p, notifications: [{ count: action.payload.count, profile_id: profile_id }] } : p);
            return {
                ...state,
                channels: channels,
                loading: false,
                totalUnread: channels.filter(p => p?.notifications?.length > 0).map(p => p.notifications.filter(p => p.profile_id === profile_id)[0]?.count).reduce((accumulator, currentValue) => accumulator + (currentValue ? currentValue : 0), 0)
            }
        case GET_MESSAGE_VIA_PUSHER:
            channels = state.channels.map(p => action.payload.channel_id === p.id
                ? {
                    ...p,
                    last_message: action.payload.message,
                    time: action.payload.time
                }
                : p);

            channels.sort((a, b) => new Date(a.time) - new Date(b.time));

            return {
                ...state,
                channels: channels,
                messageContent: (action.payload.channel_id === state.channel_id || action.payload.type === "premium/discussion")
                    && ((state.messageContent && state.messageContent.length === 0) ||
                        (state.messageContent && state.messageContent.length > 0 && action.payload.id !== state.messageContent[state.messageContent.length - 1].id)
                    )
                    ? [...state.messageContent, { id: action.payload.id, profile_id: action.payload.profile_id, message: action.payload.message, time: action.payload.time, leave: action.payload.leave, reply: action.payload.reply }]
                    : state.messageContent,
                loadMore: false,
                loading: false,
                totalUnread: channels.filter(p => p?.notifications?.length > 0).map(p => p.notifications.filter(p => p.profile_id === profile_id)[0]?.count).reduce((accumulator, currentValue) => accumulator + (currentValue ? currentValue : 0), 0),
                newMessageFlag: true
            }
        case LEAVE_CHANNEL:
            Cookies.set('channel_id', '0');
            return {
                ...state,
                channel_id: 0,
                selectedChannel: {},
                loading: false,
                channels: state.channels.filter(p => p.id !== action.payload)
            }
        case REMOVE_MESSAGE:
            const messageContent = state.messageContent.filter(p => p.id !== action.payload.message_id);
            return {
                ...state,
                messageContent: messageContent,
                loadMore: false,
                loading: false,
                newMessageFlag: false,
                channels: state.channels.map(p => p.id === action.payload.channel_id ? { ...p, last_message: messageContent.length > 0 ? messageContent.slice(-1)[0].message : '' } : p)
            }
        // discussion
        case DISCUSSION_SET_ID:
            Cookies.set('group_id', action.payload);
            return {
                ...state,
                channel_id: action.payload,
                loading: false
            }
        case UPDATE_FRIENDSHIP_STATUS_SUCCESS:
            return {
                ...state,
                updateFriendStatus: action.payload
            }
        case ADD_FRIEND_SUCCESS:
            return {
                ...state,
                addFriendStatus: action.payload
            }
        case GET_INCOMING_FRIEND_REQUESTS_SUCCESS:
            return {
                ...state,
                incomingFriendRequests: action.payload
            }
        case CHECK_FRIENDSHIP_STATUS:
            return {
                ...state,
                isFriend: action.payload
            }
        default: return state;
    }
}


export {
    messageReducer,
}