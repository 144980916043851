// en-US.js
const data = {
    homepage: {
        header_menu: {
            header_menu_1: [
                {
                    id: 1,
                    name: 'Home',
                    links: '/',
                },
                {
                    id: 2,
                    name: 'Blogs',
                    links: '/blogs',
                },
                {
                    id: 3,
                    name: 'News',
                    links: '/contributor/news',
                },
                {
                    id: 4,
                    name: 'Events',
                    links: '/contributor/events',
                },
                {
                    id: 5,
                    name: 'Directory',
                    links: '/contributor/directory',
                },
                {
                    id: 6,
                    name: "Marketplace",
                    links: "#"
                },
                {
                    id: 7,
                    name: "Community",
                    links: "/contributor/community",
                    permission: true,
                    is_premium: false,
                },
                {
                    id: 7,
                    name: "Lunc Meet",
                    links: "/premium/meet",
                    permission: true,
                    is_premium: true,
                },
                // {
                //     id: 8,
                //     name: "Premium Plan",
                //     links: '/premium-plan',
                // }
            ],
            header_menu_2: [
                {
                    id: 5,
                    name: 'Contributors',
                    links: '#'
                },
                {
                    id: 6,
                    name: 'Premium users',
                    links: '#'
                },
            ]
        },
        slider: {
            heading: "Welcome to",
            introduction_1: {
                sentence: [
                    "Welcome to Luncsphere,  the hub for the entire Luna Classic ecosystem, fostering innovation, unity,  engagement and growth. Here, you can stay up-to-date with the latest developments in the Luna Classic ecosystem, such as the launch of new applications, upcoming AMAs, NFT projects or upgrades to the network. "
                ],
                image: "guardian_1.png"
            },
            introduction_2: {
                sentence: [
                    'This platform also serves as a space for members of the Luna Classic community to connect with other community members to share ideas, chat or meet up in their local area. In addition it offers a Cryptocurrency-focused freelance marketplace, connecting clients seeking crypto-related services with freelancers who have the relevant skills and expertise. ',
                    'Our goal is to foster a supportive and collaborative environment that empowers the Luna Classic community to grow and thrive',
                ],
                image: "guardian_2.png"
            }
        },
        contributors: {
            heading: "Luncsphere for Contributors",
            body: 'As a validator, developer, NFT project, exchange or influencer, Luncsphere is the perfect tool to share your news, tutorials or events and connect with fellow Lunc thought leaders.',
            button: 'Register as a contributor'
        },
        premium_users: {
            heading: 'Luncsphere Premium',
            heading_below: 'For NFT holders and Premium subscribers, Luncsphere offers a range of additional features, exclusive launches, events and services.',
            body: {
                luncmeet: {
                    heading: "Lunc Meet",
                    body: "Luncmeets offers premium users the opportunity to search and engage with like-minded folk within the Luna Classic community. Search for other Premium users by interest or location. Share knowledge and experience and arrange meet ups either online or in person.",
                    picture_description: 'Newest Premium Members'
                },
                marketplace: {
                    heading: "Marketplace",
                    body: "Luncmarket place operates similarly to existing freelance platforms such as Upwork or Fiverr, but with the added benefit of offering services for Luna Classic payments. Freelancers can sign up and create a profile, offering their skills to potential clients within Luncsphere. Clients can also sign up and post jobs and gigs for freelancers to bid on.",
                    picture_description: "Top Premium Users"
                },
                gigs: {
                    heading: 'Top Freelance Gigs'
                }
            },
            footer: {
                button_description: 'Join Premium Users now!',
                button: 'Premium Users'
            }
        },
        news: {
            heading: 'Lunc News',
            body: 'Keep your finger on the pulse for all developments and updates relating to the Luna Classic Community. \n Your centralised news source for all things Lunc.',
            footer: {
                button: "See All News"
            }
        },
        events: {
            heading: 'Lunc Events',
            body: 'Stayed tuned in to all events through our Luncsphere calendar. For new NFT collections, DAPP launches, upgrades, AMA’s and tutorials relating to the Luna Classic blockchain.',
            footer: {
                button: 'See More Events'
            }
        },
        directory: {
            heading: 'Lunc Directory',
            body: 'Find the hottest contributors to follow in the Luna Classic community and gain access to the content that matters to remain educated and informed about your Lunc investments.',
            footer: {
                icons: [
                    {
                        icon: './assets/icon/console.png',
                        name: 'Gaming'
                    },
                    {
                        icon: './assets/icon/nft_card.png',
                        name: 'NFT'
                    },
                    {
                        icon: './assets/icon/location.png',
                        name: 'Market Place'
                    },
                    {
                        icon: './assets/icon/dapps.png',
                        name: 'Dapps'
                    },
                    {
                        icon: './assets/icon/coding.png',
                        name: 'Developer Team'
                    },
                    {
                        icon: './assets/icon/influence.png',
                        name: 'Influencer'
                    },
                    {
                        icon: './assets/icon/payment.png',
                        name: 'Payment Providers'
                    },
                ],
                button: 'Search Now'
            }
        },
        nft_showcase: {
            heading: 'NFT Showcase'
        }
    },
    contributor: {
        title: "Luncsphere Contributors",
        register_description: "Welcome to the contributor area of Luncsphere. Our platform offers free profiles to all Lunc contributors, giving them the opportunity to share media, events, updates and news with other members of the Luncsphere community. Our goal is to provide the Luna Classic community with a streamlined point of communication and broadcast. We believe that a centralised source of news and events is essential to help attract potential partnerships and facilitate collaboration among contributors. We are interested in receiving contributor applications from Validators, NFT Projects, Developers, Exchanges, Crypto Influencers and Luna Classic thought leaders. If you are interested in becoming a Luncsphere contributor, please register below.",
        login_description: "Once validated and approved, you will be sent a link to login and create your contributor profile. This will allow you to post your news and events as well as communicate with other contributors within the community. If you are already registered and validated, you can log straight in and start posting.",
        register_btn: "Register as a Contributor",
        login_btn: "Login"
    },
    contributor_login: {
        header: "Contributor Login",
        body: {
            description: "Welcome, contributors! Login and unlock a world of possibilities. Share media, events, updates, and news with the Luna Classic community. Join us now and make your mark in Luna Classic's journey!",
            user_name: "User Name",
            password: "Password",
            forgot_password: "Forgot Password?"
        },
        footer: {
            button: "Login",
            register_1: "Not Contributor yet?",
            register_2: "Register"
        }
    },
    contributor_register: {
        header: {
            title: "Contributors Registration",
            description: "Calling all contributors! register now and be part of the movement"
        },
        body: {
            label: {
                name: "Name",
                email: "Email",
                password: 'Password',
                role: "Which of the following best describes you",
                describe: "Please elaborate as to why you fall into the category of Lunc Contributor, please also include any website and/ or social media link"
            }
        },
        footer: {
            button: "Register"
        }
    },
    contributor_register_request: {
        title: "We've sent your request.",
        body: "Please wait. We will send admin's approval to your email inbox. This will take 2 or 3 hours."
    },
    contributor_becoming: {
        title: "Thank you for becoming a contributor!",
        description: "We greatly appreciate your contribution to the Luncsphere community. We wanted to inform you that an email regarding the approval\
                     of your contributor account and login information has been sent to the email address you provided during registration. Please check \
                     your inbox to find the email. Once you receive the approval, you will be able to access all the features and benefits of being a contributor.\
                     Once you have received the approval email, please click the login button below to access your contributor account. We are excited to see \
                     the incredible contributions you will bring to the Luncsphere community!\
                    If you have any questions or need further assistance, please don't hesitate to contact our team at info@luncsphere.com.",
        button: "Login Now"
    },
    contributor_forgot_password: {
        title: "Forgot Password?",
        body: "We will send an email to your email address shortly.",
        label: "Your email address"
    },
    contributor_forgot_request: {
        title: "We've sent your request.",
        body: "Please wait. We will send an email to your email inbox. Please check your email."
    },
    contributor_reset_password: {
        title: "Reset password",
        label: {
            button1: "New Password",
            button2: "Confirm Password",
        }
    },
    contributor_create_profile: {
        title: "Welcome Contributor!",
        description: [
            'We are thrilled that you have joined the Luncsphere community. Thank you for registering as a contributor. We are ready to embark on this journey together.',
            'To get started, please take a moment to fill out the form linked below. This will help us better understand your interests and expertise, ensuring that you have a tailored experience as a contributor.'
        ],
        label: [
            'Profile Image',
            'Name',
            'Description of Your Area of Influence',
            'Social Media Link (minimal 1)',
            [
                'Telegram (Optional)',
                'Twitter (Optional)',
                'Discord (Optional)',
                'Youtube (Optional)',
            ],
            'Upoload Media'
        ],
        button: "Submit",
        modals: {
            media_upload_modal: {
                title: "Add Media",
                tabs: [
                    {
                        name: "image",
                        default: true
                    },
                    {
                        name: 'video',
                        default: false
                    },
                ],
                inputs: [
                    {
                        label: "Upload image",
                        type: "upload",
                        tab: "image"
                    },
                    {
                        label: "Description",
                        type: "textarea",
                        tab: "image"
                    },
                    {
                        label: "Video Link",
                        type: 'link',
                        tab: 'video'
                    },
                    {
                        label: "Description",
                        type: 'textarea',
                        tab: 'video'
                    }
                ]
            }
        }
    },
    contributor_community: {
        title: "Contributors Community",
        description: "In the vibrant community of Luncsphere contributors, you'll find a diverse network of like-minded individuals, all passionate about Luna Classic. Here, you can connect with fellow contributors, engage in meaningful discussions, communicate directly, and collaborate on exciting projects.Discover a wealth of knowledge and expertise as you interact with contributors from various backgrounds, including Validators, NFT Projects, Developers, Exchanges, Crypto Influencers, and Luna Classic thought leaders. Exchange ideas, share insights, and explore opportunities for partnership and growth.\
                    <br><br>\
                    With our interactive platform, communication is made seamless. Connect with contributors through direct messaging, initiate group discussions, and even create dedicated forums to dive deep into specific topics. The possibilities for collaboration and learning are limitless within this thriving community.Embrace the power of collaboration and forge valuable connections with contributors who share your passion for Luna Classic. Together, we'll shape the future of Luna Classic and drive innovation in this dynamic ecosystem.",
        header: {
            title: "Contributors Community",
            body: [
                "In the vibrant community of Luncsphere contributors, you'll find a diverse network of like-minded individuals,\
                 all passionate about Luna Classic. Here, you can connect with fellow contributors, engage in meaningful discussions,\
                  communicate directly, and collaborate on exciting projects.Discover a wealth of knowledge and expertise as you \
                  interact with contributors from various backgrounds, including Validators, NFT Projects, Developers, Exchanges, \
                  Crypto Influencers, and Luna Classic thought leaders. Exchange ideas, share insights, and explore opportunities \
                  for partnership and growth.",
                "With our interactive platform, communication is made seamless. Connect with contributors through direct messaging,\
                 initiate group discussions, and even create dedicated forums to dive deep into specific topics. The possibilities \
                 for collaboration and learning are limitless within this thriving community.Embrace the power of collaboration and \
                 forge valuable connections with contributors who share your passion for Luna Classic. Together, we'll shape the \
                 future of Luna Classic and drive innovation in this dynamic ecosystem."
            ]
        },
        contributors: {
            title: "Contributors",
        },
        forum: {
            title: "Forum"
        }
    },
    contributor_directory: {
        header: {
            title: 'LUNC DIRECTORY',
            body: "The Lunc directory is the who’s who of the Luna Classic Community. \
            A space where you can discover and learn more about the brilliant minds behind\
             one of the most exciting projects in the cryptocurrency world. If you are \
             invested in Luna Classic or thinking of investing in LUNC, then you've come to\
              the right place. Our directory is made up of a diverse group of talented \
              contributors including developers, marketers, and visionaries who have \
              dedicated their time and expertise to the Luna Classic Community. You'll learn\
               about their projects, skills and their contributions to the blockchain. You \
               will also discover the innovative ideas and visions that they've brought to \
               the table, helping to shape the future of LUNC."
        },
        body: {
            title: "Lunc Contributor",

        }
    },
    contributor_events: {
        header: {
            title: 'Lunc Events',
            body: "Attending events in the Lunc space is an essential way to be educated and stay \
            up to date with the latest developments in this constantly evolving blockchain. Events \
            can provide you with invaluable insights and networking opportunities allowing you to \
            connect with like-minded Lunc followers, hear from industry experts and learn about the \
            latest developments and trends in the Luna Classic Community. From discussions and tutorials\
             to product and DAPP launches, AMA’s, and burns. Lunc events are designed to inspire and\
              educate attendees. They are also an excellent opportunity to network with industry leaders,\
               investors and developers. Start exploring the many Lunc events on offer to stay ahead of \
               the curve in the Luna classic community."
        },
        searchByDate: [
            {
                id: 1,
                title: 'Tomorrow',
            },
            {
                id: 2,
                title: 'Next Week',
            },
            {
                id: 3,
                title: 'Today',
            },
            {
                id: 4,
                title: 'This Weekend',
            },
        ]
    },
    contributor_blogs: {
        header: {
            title: 'Blogs & Articles',
            body: "Our goal is to provide useful and informative content that will help you navigate the world of Luna Classic. Whether you are an experienced crypto investor a blockchain enthusiast or someone who is just getting started, our blog page is the perfect place to learn more about the Luna Classic ecosystem. We will cover a wide range of topics including Luna Classic upcoming developments, educational articles and ways to get involved in the Luna Classic community. Our bloggers will provide insights into the latest trends and innovations in the world of blockchain and cryptocurrency and we’ll explore how these developments are shaping the future of Luna Classic. We also want to hear from you! We welcome guest posts from members of the Luna Classic community."
        }
    },
    contributor_news: {
        header: {
            title: 'Lunc News',
            body: "From in-depth analysis of market trends and expert insights from industry leaders\
             and Luna Classic enthusiasts, Lunc News provides a comprehensive compilation of news and\
              updates to help you make informed decisions about Lunc investment. Whether you're looking\
               to expand your knowledge, stay up-to-date on the latest performance and trends, or just \
               learn more about the exciting world of Luna Classic, our news page has everything you \
               need to keep you informed."
        }
    },
    terms_and_conditions: [
        [
            'Introduction: By accessing using or registering on this community webplatform you agree to comply with the following terms and conditions. These terms and conditions govern your access to and use of the webplatform. If you do not agree with these terms and conditions you should not use the webplatform.',
            'Respect: This webplatform is a space for individuals to learn share and connect in a safe and respectful environment. All users are expected to behave in a respectful and courteous manner towards each other. Any disruptive or abusive behaviour will not be tolerated and such users will be blocked from accessing the webplatform.',
            'Intellectual Property: All content uploaded embedded transmitted on or through the webplatform including but not limited to text images audio videos graphics software and trademarks and logos are owned by the webplatform owner or licensed to the webplatform owner. You are not allowed to download copy or reproduce any of this content for commercial use or redistribution without prior written consent from the webplatform owner.',
            'User Conduct: By accessing and using the webplatform you agree to use it responsibly and not for illegal or unethical purposes. You agree not to post or transmit any content that is illegal defamatory offensive unlawful pornographic or promotes discrimination based on race gender sexual orientation religion etc. Any user posting such content will be removed from the webplatform.',
            'Privacy: We respect your privacy and are committed to protecting your personal information. Please refer to our privacy policy for more information about how we collect use and store your personal data. ',
            'Liability: The webplatform owner is not responsible for any damages or losses incurred by users while using the webplatform. The webplatform owner is also not responsible for any content posted by users on the webplatform. All users are responsible for their actions and must comply with any applicable laws when using the webplatform. ',
            'Termination: We reserve the right to terminate or limit your access to the webplatform for any reason with or without notice. We also reserve the right to modify or discontinue the webplatform at any time without prior notice.',
        ],
        [
            'All deals and transactions should be made through the platform. Any attempt to conduct business outside the platform will be subject to immediate suspension or termination of your account. ',
            'The platform acts as an intermediary for all transactions. We facilitate the transactions between the buyers and the freelancers to ensure quality delivery and satisfaction.',
            'The platform has the right to investigate and monitor suspicious activities that may be in violation of our policies or the law. ',
            'The platform does not assume responsibility for any damage or loss that occurs as a result of transactions conducted outside of the platform. ',
            'By using the platform you agree to participate in transactions and deals that are conducted solely through the platform. ',
            'The platform has the right to take any action it deems necessary to prevent fraudulent or dishonest activities. ',
            'The platform reserves the right to suspend or terminate your account if you violate any of these terms and conditions. ',
            'The freelancers and buyers hold all responsibility for the quality and delivery of their products and services. The platform does not assume responsibility for any disputes or dissatisfaction with the transaction. ',
            'The platform cannot be held liable for any loss or damage incurred as a result of the use of the platform. ',
            'By using the platform you agree to comply with all applicable laws and regulations governing the use of a cryptocurrency freelance marketplace.',
            'Governing Law: These terms and conditions are governed by the laws of the country where the webplatform owner is based. Any disputes arising from the use of the webplatform will be subject to the exclusive jurisdiction of the courts in that country.',
        ]
    ],
    premium: {
        header: {
            title: "Luncsphere Premium",
            body: "For NFT holders and premium subscribers Luncsphere offers a range of\
             exclusive features that allows them to access to gated content, participate in\
              events and engage with the Luna Classic community in new ways. It offers a \
              unique value proposition for the growing LUNC community, providing a dedicated \
              platform to connect with those with a shared interest in the currency.\
            Simply register your account and simply connect your Terra wallet via wallet\
            connect  to unlock access to a range of additional benefits."
        },
        benefits: [
            {
                title: 'crypto market place',
                body: "Offer or search for specialist freelance crypto services among the\
                 Luna Classic community. From content creators to video editors, blockchain\
                  legal experts or graphic designers.Freelancers on the platform can create\
                   a profile showcasing their skills along with their rates and portfolio.\
                    Clients looking to hire freelancers can search for the services they \
                    require and browse through different profiles to find the right match.\
                    Our marketplace operates on a decentralised platform, using blockchain \
                    technology to create a secure and transparent platform for freelancers to\
                     offer their services and receive payments in Luna Classic."
            },
            {
                title: "lunc meet",
                body: "Search and connect with other members of the Luna Classic community.\
                 Create your own profile and discover other members based on location and\
                  interests to chat or meet up with fellow premium users. Join forums or \
                  group chats to share insights and experiences"
            },
            {
                body: "Once you are validated as a premium user, you will be sent a link to\
                 login to the Premium User area. If you are already registered, log straight\
                  in here"
            }
        ]
    },
    premium_becoming: {
        title: "Thank you for becoming Premium User",
        description: "We greatly appreciate your contribution to the Luncsphere community. We wanted to inform you that an email regarding the approval of your contributor account and login information has been sent to the email address you provided during registration. Please check your inbox to find the email.Once you receive the approval, you will be able to access all the features and benefits of being a Premium User. Once you have received the approval email, please click the login button below to access your Premium user account.We are excited to see the incredible contributions you will bring to the Luncsphere community! If you have any questions or need further assistance, please don't hesitate to contact our team at info@luncsphere.com.",
        button: "Login Now"
    },
    premiumPlan: {
        description: 'Luncsphere provides NFT holders with exclusive benefits such as premium features, granting them access to gated content only available to NFT holders. Additionally, they can participate in various events and actively connect with the Luna Classic community through Lunc Meets. Furthermore, NFT holders have the opportunity to offer their services as crypto freelancers or post crypto-related job opportunities on our specialized upcoming freelance crypto marketplace. ',
        premiumMembershipTitle: 'As a NON NFT holder, we offer the following subscription plans,  to grant access to Luncsphere PREMIUM.',
        memberships: [
            {
                period: 12,
                unit: 'month',
                amount: 1000000
            },
            {
                period: 3,
                unit: 'month',
                amount: 300000
            }
        ]
    },
    premium_marketplace: {
        header: {
            title: 'CRYPTO MARKETPLACE',
            body: "The Luncmarket place has been designed to connect freelancers among\
             the premium Luncsphere community with those that require their services with\
              the added benefit of trading services for payments in Luna Classic. If you have\
               a service to offer or a job that needs filling, Marketplace is the perfect place\
                to find your people."
        },
        button_text: {
            myTransaction: 'My Transaction',
            amFreelancer: 'I AM A FREELANCER',
            needFreelancer: 'I NEED A FREELANCER',
            seeMore: 'See More'

        },
        body: {
            title_1: "Our Lasest Freelance",
            title_2: "Top Freelance Gigs",
        },
        freelance_item: {
            button_1: "See profile",
            button_2: 'Make a deal'
        },
        freelance_gig: {
            button_1: 'Copywriter',
            button_2: "Content Creator",
            button_3: "See More"
        }
    },
    premium_meet: {
        header: {
            title: "LUNC MEET",
            body: "Search and connect with other members of the Luna Classic community. Create your own profile and discover other members based on location and interests to chat or meet up with fellow premium users. Join forums or group chats to share insights and experiences"
        },
        group: {
            name: "Groups",
            button: "Create a group",
            no_groups: 'No groups',
            no_events: "No events",
            no_users: "No Users"
        },
        events: {
            name: 'Events',
            see_more: 'See More'
        },
        meet_group_modal: {
            group_name: 'Group Name',
            group_topic: "Group Topic",
            about_group: "About Group",
            group_logo: 'Group Logo',
            new_group: "New Group",
            group_settings: "Group Settings",
        },
        meet_new_event_modal: {
            edit_event: 'Edit Event',
            new_event: 'New Event',
            add_some_layer_or_image: "add some layer or image",
            event_name: 'Event Name',
            event_platform: 'Event Platform',
            offline: "Offline",
            online: "Online",
            event_location: 'Event Location',
            event_link: 'Event Link',
            event_topic: 'Event Topic',
            event_description: 'Event Description',
            calender: 'When Your Luncmeet will be held',
            cancel: 'Cancel',
            make_luncmeets: 'Create event'
        },
        premium_meet_group: {
            members: 'Members',
            about: 'About',
            event: 'Event',
            group_discussion: 'Group Discussion',
            about_this_group: "About this group",
            events: 'Events',
            make_luncmeets_events: "Make Luncmeets Events",
            group_setting: 'Group Setting',
            no_events: 'No Events'
        },
        premium_all_events: {
            premium_other_users: 'Other Premium Users',
            premium_other_events: 'Events',
            no_yet: 'No Events yet',
            search: 'Search',
            search_by: 'Search by',
            all: 'All',
            country: 'Country',
            city: 'City',
            distance: 'Distance',
            see_profile: "See profile",
            send_message: 'Send Message',
            interests: "Interests",
        }
    },
    premium_message: {
        editor: {
            image: {
                title: "Choose Image",
                upload: 'Upload Image',
                cancel: 'Cancel',
                send: "Send"
            }
        }
    },
    premium_profile: {
        tab: {
            tab_1: 'Personal Profile',
            tab_2: 'Professional Profile'
        },
        button: {
            send_message: "Send Message",
            edit_profile: "Edit Profile",
            inbox: 'Friend Request',
            friends_list: 'Friends List',
            direct_message: 'Direct Message',
            friend: 'Friends',
            addFriend: 'Add Friend',
            requested: 'Requested',
            rejected: 'Rejected',
        },
        left_sidebar: {
            my_details: 'My Details',
            services_offered: 'Services Offered',
            my_reviews: 'My Reviews',
            no_service_contents: 'No service contents',
            no_assigned_jobs: 'No assigned jobs'
        },
        body: {
            about_me: 'About Me',
            my_groups: 'My Groups',
            professional_bio: 'Professional Bio',
            my_portfolio: 'My Portfolio',
            add: 'Add'
        },
        modal: {
            image: 'Upload Portfolio Cover',
            name: 'Project Name',
            des: 'Project Description',
            upload: 'File(optional) | Up to 10MB (png,jpg,jpeg,docx,PDF,MP4)',
            cancel: 'Cancel',
            save: 'Save'
        }
    },
    premium_login: {
        header: {
            premium_login: 'Premium Login',
            des: 'Welcome, premium! Login and unlock a world of possibilities. Share media, events, updates, and news with the Luna Classic community. Join us now and make your mark in Luna Classic\'s journey!'
        },
        body: {
            email: 'Email',
            password: 'Password',
            forgot_password: 'Forgot Password?',
            not_premium_yet: "Not premium user yet?",
            login: 'Login',
            register: 'Register',
            add: '+ Add'
        },

    },
    freelance_profile: {
        modal: {
            image: 'Image',
            name: 'Project Name',
            des: 'Project Description',
            upload: 'File(optional) | Up to 10MB',
            cancel: 'Cancel',
            save: 'Save'
        }
    }
}

export default data;