import requests from '../httpService';
import {
  CONTRIBUTORS_REQUEST,
  CONTRIBUTORS_SUCCESS,
  CONTRIBUTORS_FAIL,
  PROFILE_BY_ID_REQUEST,
  PROFILE_BY_ID_SUCCESS,
  PROFILE_BY_ID_FAIL,
  FEATURED_CONTRIBUTOR,
} from 'constants/contributorConstants';
import { notifyError } from 'utils/toast';

const getContributors = (query) => async (dispatch) => {
  const dest = query?.type ? query?.type : "contributors";
  const link = dest !== "premium" ? "get-contributors" : "get-users-premium";
  dispatch({ type: CONTRIBUTORS_REQUEST, payload: { query } })
  await requests.post(`/${dest}/${link}`, query)
    .then((res) => {
      if (res) {
        dispatch({ type: CONTRIBUTORS_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: CONTRIBUTORS_FAIL, payload: err })
    })
}

const getProfileById = (data) => async (dispatch) => {
  dispatch({ type: PROFILE_BY_ID_REQUEST, payload: { id: data.profileId } })
  await requests.get(`/${data.type}/${data.profileId}/get-profile-by-id`)
    .then((res) => {
      if (res) {
        dispatch({ type: PROFILE_BY_ID_SUCCESS, payload: res })
      }
    })
    .catch((err) => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: PROFILE_BY_ID_FAIL, payload: err })
    })
}

const getFeaturedContributor = () => async (dispatch) => {
  await requests.get('/contributors/get-featured-one')
    .then(res => {
      if (res) {
        dispatch({ type: FEATURED_CONTRIBUTOR, payload: res })
      }
    })
    .catch(err => {
      notifyError(err?.response?.data?.message ? err?.response?.data?.message : err?.message);
      dispatch({ type: FEATURED_CONTRIBUTOR, payload: err })
    })
}

export {
  getProfileById,
  getContributors,
  getFeaturedContributor
}