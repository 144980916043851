import React from "react";
import PageNotFound from "./PageNotFound";


import PrivateRoute from "components/login/PrivateRoute";
import ContributorProfilePrivateRoute from "components/login/ContributorProfilePrivateRoute";
import ContributorMessage from "./ContributorMessage";
import PremiumMessageRequest from "./premium/PremiumMessageRequest";

// import PremiumPrivateRoute from "components/login/PremiumPrivateRoute";

const Home01 = React.lazy(() => import("./Home01"));
const ContributorLogin = React.lazy(() => import("./ContributorLogin"));
const ContributorRegister = React.lazy(() => import("./ContributorRegister"));
const ContributorRegisterRequest = React.lazy(() => import("./ContributorRegisterRequest"));
const ContributorForgotPassword = React.lazy(() => import("./ContributorForgotPassword"));
const ContributorForgotRequestSent = React.lazy(() => import("./ContributorForgotRequestSent"));
const ContributorResetPassword = React.lazy(() => import("./ContributorResetPassword"));
const ContributorCreateProfile = React.lazy(() => import('./ContributorCreateProfile'));
// const ContributorProfile = React.lazy(() => import('./ContributorProfile'));
const Contributor = React.lazy(() => import('./Contributor'))
const ContributorBecoming = React.lazy(() => import('./ContributorBecoming'))
const ContributorDashboard = React.lazy(() => import('./ContributorDashboard'))
const ContributorAddProfile = React.lazy(() => import('./ContributorAddProfile'))
const ContributorCommunity = React.lazy(() => import("./ContributorCommunity"))
const ContributorCommunityContributors = React.lazy(() => import("./ContributorCommunityContributors"))
const ContributorCommunityForums = React.lazy(() => import("./ContributorCommunityForums"))
const ContributorForumDetail = React.lazy(() => import("./ContributorForumDetail"))
const ContributorPostForum = React.lazy(() => import('./ContributorPostForum'))
const ContributorDirectory = React.lazy(() => import('./ContributorDirectory'))
const ContributorEvent = React.lazy(() => import('./ContributorEvent'))
const ContributorEventDetail = React.lazy(() => import('./ContributorEventDetail'))
const ContributorBlogs = React.lazy(() => import('./ContributorBlogs'))
const ContributorBlogsAdd = React.lazy(() => import('./ContributorBlogsAdd'))
const ContributorBlogsDetail = React.lazy(() => import('./ContributorBlogsDetail'))
const ContributorNews = React.lazy(() => import('./ContributorNews'))
const ContributorNewsDetail = React.lazy(() => import('./ContributorNewsDetail'))
const ContributorProfileView = React.lazy(() => import('./ContributorProfileView'))
const CustomCalendar = React.lazy(() => import('../utils/calendar'))
const ComingSoon = React.lazy(() => import('./ComingSoon'))
const TermsAndConditions = React.lazy(() => import('./TermsAndConditions'))
const PremiumUser = React.lazy(() => import('./premium/PremiumUser'))
const PremiumRegister = React.lazy(() => import('./premium/PremiumRegister'))
const PremiumPlan = React.lazy(() => import('./premium/PremiumPlan'))
const PremiumProfile = React.lazy(() => import('./premium/PremiumProfile'))
// const PremiumMeetGroup = React.lazy(() => import('./premium/CryptoMarketplace'))
const PremiumMeet = React.lazy(() => import('./premium/PremiumMeet'))
const PremiumLogin = React.lazy(() => import('./premium/PremiumLogin'))
const PremiumMeetGroup = React.lazy(() => import('./premium/PremiumMeetGroup'))
const PremiumMeetGroupSetting = React.lazy(() => import('./premium/PremiumMeetGroupSetting'))
const PremiumMeetOtherEvents = React.lazy(() => import('./premium/PremiumMeetOtherEvents'))
const PremiumMeetOtherUsers = React.lazy(() => import('./premium/PremiumMeetOtherUsers'))
const PremiumMeetOtherGroups = React.lazy(() => import('./premium/PremiumMeetOtherGroups'))
const PremiumInbox = React.lazy(() => import('./premium/PremiumInbox'))
const PremiumFriendsList = React.lazy(() => import('./premium/PremiumFriendsList'))
const PremiumMessage = React.lazy(() => import('./premium/PremiumMessage'))
const PremiumBecoming = React.lazy(() => import('./premium/PremiumBecoming'))

const routes = [
  { path: '/', component: <Home01 /> },
  { path: '*', component: <PageNotFound /> },
  { path: '/coming-soon', component: <ComingSoon /> },
  { path: '/contributor', component: <Contributor /> },
  { path: '/contributor-login', component: <ContributorLogin /> },
  { path: '/contributor-register', component: <ContributorRegister /> },
  { path: '/contributor-register-request-sent', component: <ContributorRegisterRequest /> },
  { path: '/contributor-becoming', component: <ContributorBecoming /> },
  { path: '/:type/forgot-password', component: <ContributorForgotPassword /> },
  { path: '/contributor/forgot-password-request-sent', component: <ContributorForgotRequestSent /> },
  { path: '/:type/reset-password/:email/:token', component: <ContributorResetPassword /> },
  { path: '/terms-and-conditions', component: <TermsAndConditions /> },
  { path: '/contributor/:profileId/my-profile', component: <PrivateRoute><ContributorCreateProfile /></PrivateRoute> },
  { path: '/contributor/add-profile', component: <PrivateRoute><ContributorAddProfile /></PrivateRoute> },
  { path: '/contributor/dashboard/:profileId', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorDashboard /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/community', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorCommunity /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/community/post-forum', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorPostForum /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/community/forums', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorCommunityForums /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/community/forum-detail/:id', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorForumDetail /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/directory', component: <ContributorDirectory /> },
  { path: '/contributor/events', component: <ContributorEvent /> },
  { path: '/contributor/events/:id/detail', component: <ContributorEventDetail /> },
  { path: '/blogs', component: <ContributorBlogs /> },
  { path: '/blogs/add', component: <ContributorBlogsAdd /> },
  { path: '/blogs/:id/detail', component: <ContributorBlogsDetail /> },
  { path: '/contributor/news', component: <ContributorNews /> },
  { path: '/contributor/news/:id/detail', component: <ContributorNewsDetail /> },
  { path: '/contributor/:id/profile-view', component: <ContributorProfileView /> },
  { path: '/contributor/calendar', component: <PrivateRoute><ContributorProfilePrivateRoute><CustomCalendar /></ContributorProfilePrivateRoute></PrivateRoute> },
  // { path: '/contributor/ckeditor', component: <PrivateRoute><ContributorProfilePrivateRoute><CustomCkeditor /></ContributorProfilePrivateRoute></PrivateRoute>},
  { path: '/contributor/contributor-message', component: <PrivateRoute><ContributorProfilePrivateRoute><ContributorMessage /></ContributorProfilePrivateRoute></PrivateRoute> },
  { path: '/contributor/community/contributors', component: <ContributorCommunityContributors /> },
  { path: '/premium', component: <PremiumUser /> },
  { path: '/premium-register', component: <PremiumRegister format="register" /> },
  { path: '/premium/:id/profile-edit', component: <><PremiumRegister format="edit" /> </> },
  // { path: '/premium/message', component: <PrivateRoute userType="premium"><><ContributorMessage /></></PrivateRoute> },
  { path: '/premium/message', component: <PrivateRoute userType="premium"><><PremiumMessage /></></PrivateRoute> },
  { path: '/premium/message-request', component: <PrivateRoute userType="premium"><><PremiumMessageRequest /></></PrivateRoute> },
  { path: '/premium/inbox', component: <PrivateRoute userType="premium"><><PremiumInbox /></></PrivateRoute> },
  { path: '/premium/friends-list', component: <PrivateRoute userType="premium"><><PremiumFriendsList /></></PrivateRoute> },
  { path: '/premium-plan', component: <PremiumPlan /> },
  { path: '/premium/:id/profile', component: <PrivateRoute userType="premium"><><PremiumProfile /></></PrivateRoute> },
  // { path: '/premium-marketplace', component: <PrivateRoute userType="premium"><><PremiumCryptoMarketPlace /></></PrivateRoute> },
  { path: '/premium/meet', component: <PrivateRoute userType="premium"><><PremiumMeet /></></PrivateRoute> },
  { path: '/premium/meet/:groupId/group', component: <PrivateRoute userType="premium"><><PremiumMeetGroup /></></PrivateRoute> },
  { path: '/premium/meet/:groupId/group-setting', component: <PrivateRoute userType="premium"><><PremiumMeetGroupSetting /></></PrivateRoute> },
  { path: '/premium/meet/other-events/:all', component: <PrivateRoute userType="premium"><><PremiumMeetOtherEvents /></></PrivateRoute> },
  { path: '/premium/meet/other-users/:all', component: <PrivateRoute userType="premium"><><PremiumMeetOtherUsers /></></PrivateRoute> },
  { path: '/premium/meet/other-groups/:all', component: <PrivateRoute userType="premium"><><PremiumMeetOtherGroups /></></PrivateRoute> },
  { path: '/premium-login', component: <PremiumLogin /> },
  { path: '/premium-becoming', component: <PremiumBecoming /> },
]

export default routes;