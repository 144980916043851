import React, { useEffect, useState, useCallback } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Cookies from 'js-cookie';
import { RiSendPlane2Fill } from "react-icons/ri";
import { BsEmojiSmile, BsArrowReturnLeft } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { CiImageOn } from 'react-icons/ci'
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import emoji from "@jukben/emoji-search";
import { useDispatch } from "react-redux";
import { sendMessageAction } from "actions/common/messageActions";
import Modal from "utils/Modal";
import MessageUploadModal from "components/modals/MessageUploadModal";
import { checkBase64 } from "utils/checkBase64";
import Autolinker from 'autolinker';
import htmlToPlainText from "utils/htmlToPlainText";

const MessageEditor = ({
  setMessage,
  message,
  channel_id,
  profile_id,
  replyMessage,
  setReplyMessage,
  messageContentHeight = "80vmin",
  messageContentPadding = 80,
  getTimeFormat,
  members,
  type
}) => {
  const dispatch = useDispatch();

  const userInfo = Cookies.getJSON('userInfo') || null

  const [emojiFlag, setEmojiFlag] = useState(false);
  const [image, setImage] = useState(null);
  const [sendImage, setSendImage] = useState(false);
  const [caretFlag, setCaretFlag] = useState(false);
  const Item = ({ entity: { name, char } }) => <div className="text-black">{`${name}: ${char}`}</div>;
  const MembersOnChannel = ({ entity: { alias, avatar } }) => <div className="bg-[var(--primary-color6)]"><img src={process.env.REACT_APP_S3_BASE_URL + avatar} className=" w-10 h-10 rounded-full mr-3 inline-block" />
        <div className="text-white inline-block">{`${alias.replaceAll(' ', '').replace('#', '')}`}</div></div>;
  const Loading = ({ data }) => <div>Loading</div>;
  const autolinker = new Autolinker({newWindow: true});

  const handleSend = () => {
    if (sendImage ? false : message === '' || message === '\n') return;
    const data = {
      channel_id: channel_id,
      profile_id: profile_id,
      message: autolinker.link(message.trim()),
      time: new Date().toUTCString(),
      type: type,
      image: checkBase64(image) ? image : null,
      reply: replyMessage ? {
        profile_id: replyMessage.profile_id,
        message: replyMessage?.message ? replyMessage?.message.substr(0, 80) + "..." : null,
        image: replyMessage.image ? replyMessage.image : null,
        time: (new Date(replyMessage.time)).toUTCString()
      }
        : null
    };
    handleSendImageDown()
    dispatch(sendMessageAction(data))
    setReplyMessage(null);
    setMessage('');
    setEmojiFlag(false);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    }

    if (e.key === 'Enter' && e.shiftKey) {
      return;
    }
    
    if (e.key === "Enter" && !caretFlag) {
      e.preventDefault();
      if (message === '' || message === '\n') {
        setMessage('')
        return;
      }
      handleSend();
      return;
    }
    const element = document.querySelector('.rta__autocomplete.rta__autocomplete--right');
    if (element) {
      setCaretFlag(true);
    } else {
      setCaretFlag(false);
    }
  }


  const handleEmojiClick = (e) => {
    setMessage(message + e.native);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const onDropImage = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result)
      }
      reader.readAsDataURL(file)
      return file;
    })
  })

  const handleDeleteImage = () => {
    setImage(null)
  }

  const handleSendImageDown = () => {
    setSendImage(false);
  };

  return (
    <>
      <div
        className={`emoji-container w-full absolute ${emojiFlag ? 'emoji-show' : 'emoji-hidden'}`}
        style={{
          maxHeight: emojiFlag ? messageContentHeight : '0',
          overflow: 'auto',
          bottom: messageContentPadding + 'px'
        }}
      >
        <Picker data={data} onEmojiSelect={handleEmojiClick} />
      </div>
      <div className="contributor-message-room-textarea absolute bottom-0">
        <div>
          {replyMessage != null &&
            <div className={`flex justify-between mx-6 py-3 border-b reply-border-color`}>
              <div className="flex">
                <BsArrowReturnLeft className="text-3xl" />
                <div>
                  {replyMessage?.message && <pre className="whitespace-pre-line border-none bg-none italic text-white" style={{ background: 'none' }}>{htmlToPlainText(replyMessage?.message).substr(0, 80) + "..."}</pre>}
                  {replyMessage?.image && !replyMessage?.message && <img src={process.env.REACT_APP_S3_BASE_URL + replyMessage?.image} className="w-full h-60 rounded-lg ml-5 my-5" />}
                  <div className=" opacity-50 text-sm">{getTimeFormat(replyMessage.time)}</div>
                </div>
              </div>
              <RxCross1 className="text-3xl" onClick={() => setReplyMessage(null)} />
            </div>
          }
          <div className="w-full relative px-4 flex justify-between items-center">
            <BsEmojiSmile size={24} className="i-emoji-selector cursor-pointer" onClick={() => setEmojiFlag(!emojiFlag)} />
            <CiImageOn className="cursor-pointer ml-5" size={28} onClick={() => setSendImage(true)} />
            <ReactTextareaAutocomplete
              className="message-textarea"
              placeholder="Start a new  message (max 5.000)"
              loadingComponent={Loading}
              style={{
                fontSize: "18px",
                lineHeight: "20px",
                padding: "5px",
                height: "30px",
                resize: "none",
                fontStyle: message !== '' ? 'normal' : 'italic'
              }}
              containerStyle={{
                margin: "10px",
              }}
              dropdownStyle={{
                top: "unset!important",
                bottom: "100%"
              }}
              listStyle={{
                backgroundColor: "var(--primary-color6)",
                padding: ".5rem",
                border: "none"
              }}
              maxLength={5000}
              onChange={handleMessage}
              onKeyUp={handleKeyUp}
              value={message}
              minChar={0}
              trigger={{
                ":": {
                  dataProvider: token => {
                    return emoji(token)
                      .slice(0, 10)
                      .map(({ name, char }) => ({ name, char }));
                  },
                  component: Item,
                  output: (item, trigger) => {
                    return item.char
                  }
                },
                '@': {
                  dataProvider: token => {
                    return members.filter(p => p.alias.includes(token) && userInfo.length !== null && p.alias !== userInfo[0]?.profiles[0]?.alias || "" );
                  },
                  component: MembersOnChannel,
                  output: (item, trigger) => '@' + item.alias.replaceAll(' ', '').replace('#', '')
                }
              }}
            />
            <RiSendPlane2Fill className="i-send cursor-pointer" onClick={handleSend} />
          </div>
        </div>
      </div>
      <Modal open={sendImage} onClose={handleSendImageDown}>
        <MessageUploadModal
          image={image}
          handleDeleteImage={handleDeleteImage}
          onDropImage={onDropImage}
          open={sendImage}
          handleModalDown={handleSendImageDown}
          handleSendMessage={handleSend}
        />
      </Modal>
    </>
  )
}

export default MessageEditor