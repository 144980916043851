import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import requests from 'actions/httpService';

export const ContributorProfileContext = createContext();

export const ContributorProfileProvider = ({ children }) => {
  const [isProfileComplete, setIsProfileComplete] = useState(true);

  // Add a function that sets isProfileComplete to true
  const markProfileAsComplete = () => {
    const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null
    userInfo?.id && (
        requests.get(`/contributors/${userInfo.id}/has-profile`)
             .then((res) => {
                res === true ? setIsProfileComplete(true) : setIsProfileComplete(false)
             })
    )
  };

  useEffect(() => {
    markProfileAsComplete();
  }, [])

  return (
    <ContributorProfileContext.Provider value={{ isProfileComplete, markProfileAsComplete }}>
      {children}
    </ContributorProfileContext.Provider>
  );
};